html,
body {
	padding: 0px;
	margin: 0px;
	font-family: Poppins, Calibri;
	background-color: #f1f3f6;
}

* {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

button,
input,
textarea,
select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	outline: none;
	min-width: 0;
	margin: 0;
	padding: 0;
}

html,
body,
#root,
.app {
	min-height: 100vh;
}

$accent_dark: #76879d;
$accent_gray: #a6b1bf;
$accent_medium: #e5e6e9;
$accent_light: #f4f5f7;

$main_color: #184fff;
$second_color: #00c2ff;
$light_blue: #d2edfd;
$dark_blue: #0a0f6b;
$dark_gray: #3a4554;
$blue: #3498db;
$green: #2ecc71;
$yellow: #f39c12;
$red: #e74c3c;

@for $p from 1 through 50 {
	.w#{$p * 2} {
		width: 2% * $p;
		flex-basis: 2% * $p;
		// flex: 1 1 $p * 5%;
	}
}

.mt0 {
	margin-top: 0px !important;
}

.mb0 {
	margin-bottom: 0px !important;
}

.flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.column-flex {
	display: flex;
	flex-direction: column;
}

.gap-8px {
	gap: 8px;
}

.mt12 {
	margin-top: 12px;
}

.flex-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
}

:root {
	--fs16: 16px;
	--fs15: 15px;
	--fs14: 14px;
	--fs13: 13px;
	--fs12: 12px;
}

@media screen and (max-width: 1100px) {
	:root {
		--fs14: 3vw;
	}
}

@function toRem($value) {
	$remValue: calc($value / 16) + rem;
	@return $remValue;
}

.app-header {
	display: flex;
	width: 100%;
	height: 66px;
	justify-content: center;
	align-items: center;
	// position: relative;
	// padding: 13px;
	position: sticky;
	top: 0px;
	z-index: 10000;

	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.08);
	// border-radius: 0px 0px 12px 12px;

	.wrap {
		margin: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-width: 1100px;
	}

	.logo {
		// background-color: $accent_light;
		// color: $accent_dark;
		// padding: 10px 16px;
		// border-radius: 4px;
		// font-weight: 600;
		// font-size: toRem(14);
		// line-height: 20px;
		transition: 0.25s;

		img {
			display: block;
			height: 40px;
		}

		&:hover {
			filter: drop-shadow(0px 4px 8px rgba(0, 103, 255, 0.3));
		}
	}

	@keyframes marked {
		0% {
			background-color: $main_color;
		}

		50% {
			background-color: $second_color;
		}

		100% {
			background-color: $main_color;
		}
	}

	.btn {
		font-weight: 400;
		font-size: toRem(14);
		line-height: 24px;
		padding: 8px 16px;
		border-radius: 4px;
		background-color: $main_color;
		color: white;
		white-space: nowrap;
		transition: 0.25s;
		cursor: pointer;

		&:hover {
			background-color: lighten($color: $main_color, $amount: 5);
		}

		&.marked {
			animation: marked infinite ease-in-out 2s;
		}
	}

	.search {
		margin-left: 24px;
		font-weight: 400;
		font-size: toRem(14);
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #eaf1fb;
		padding: 1px;
		border-radius: 4px;

		.field {
			width: 180px;
			padding: 8px 16px;
			color: $accent_dark;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 4px 0px 0px 4px;
			// background-color: $accent_light;
			transition: 0.25s;

			&:hover,
			&:focus {
				background-color: $accent_light;
			}

			img {
				margin-right: 8px;
			}

			input {
				flex: 1;
				padding: 0px;
				background-color: transparent;
				border: none;

				&::placeholder {
					color: #a6b1bf;
				}
			}
		}

		.type {
			width: 125px;
			border-radius: 4px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: $second_color;
			color: white;

			img {
				width: 10px;
			}
		}
	}

	.menu-links {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// gap: 10px;

		.btn {
			margin-left: 10px;
			height: 40px;

			&.icon {
				display: flex;
				// padding: 8px;

				span {
					// font-size: toRem(12);
					margin-left: 5px;
				}
			}

			img {
				height: 24px;
				display: block;
			}
		}

		.item {
			margin-left: 10px;
			white-space: nowrap;
			transition: 0.25s;
			cursor: pointer;

			&:hover {
				color: black;
			}
		}

		.text-btn {
			margin: 0 30px;

			&:hover {
				color: $main_color;
			}
		}
	}

	.links {
		margin: 0px 40px;
		font-weight: 400;
		font-size: toRem(14);
		line-height: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		letter-spacing: 0.25px;

		.link {
			color: $accent_dark;
			transition: 0.25s;
			cursor: pointer;

			&:hover {
				&:hover {
					color: #3c4757;
				}
			}

			&:nth-child(2) {
				margin: 0px 16px;
			}
		}
	}

	.mobile-chat {
		display: none;
		margin-right: 10px;

		img {
			width: 24px;
			display: block;
		}
	}

	.visits {
		margin-left: 10px;
	}

	.auth {
		min-width: 150px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		font-weight: 400;
		font-size: toRem(14);
		line-height: 20px;
		color: $accent_dark;

		&.full {
			flex: 1;
			justify-content: flex-end;
		}

		.title {
			display: block;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 14px;
			color: #a6b1bf;
			text-transform: capitalize;
			margin-top: 2px;
		}

		.value {
			font-weight: 500;
			font-size: toRem(16);
			line-height: 18px;
			color: black;
			max-width: 90px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.user {
			text-align: left;
			// margin: 0px 16px;
			// margin-right: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			cursor: pointer;
			margin-left: 10px;
			padding-left: 10px;
			border-left: 1px solid rgba(0, 0, 0, 0.1);

			&:hover .avatar {
				transform: scale(1.1);
			}

			.avatar {
				width: 40px;
				height: 40px;
				margin-right: 16px;
				border-radius: 8px;
				overflow: hidden;
				transition: 0.25s;
				object-fit: cover;
			}

			.arrow {
				display: block;
				margin-left: 10px;
				transition: 0.25s;

				&.opened {
					transform: rotate(180deg);
				}
			}

			.menu {
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.25s;
				position: absolute;
				left: -10px;
				right: -10px;
				top: calc(100% + 20px);
				padding: 8px 0px;
				background-color: white;
				box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.2);
				border-radius: 8px;
				overflow: hidden;
				z-index: 1000;

				// display: flex;
				// flex-direction: column;
				// justify-content: space-between;
				// align-items: stretch;

				.item {
					display: block;
					padding: 8px 16px;
					transition: 0.25s;
					cursor: pointer;

					font-weight: 400;
					font-size: toRem(14);
					line-height: 24px;
					color: $accent_dark;
					// color: black;

					&.mobile-balance {
						display: none;
						background-color: $accent_gray !important;
						color: white;
						justify-content: space-between;
						align-items: center;
						padding: 12px 16px;
						margin-top: -8px;
						cursor: default;
					}

					&.create {
						color: $blue;
					}

					&.signout {
						color: $red;
					}

					&:hover {
						background-color: $light_blue;
					}
				}
				.jobs {
					display: none;
				}

				.separator {
					font-size: 0px;
					height: 1px;
					background-color: $accent_light;
					// margin: 8px 0px;
				}

				&.show {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.balance {
			margin: 0px 16px;
			padding: 0px 16px;
			border-left: 1px solid $accent_light;
			border-right: 1px solid $accent_light;
		}

		.login {
			cursor: pointer;
		}

		// .signup {
		// padding: 10px 16px;
		// }
	}
}

.app-footer {
	background-color: white;

	.wrap {
		width: 1100px;
		margin: auto;
	}

	.links,
	.bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px 0px;
	}

	.bottom {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		font-weight: 400;
		font-size: toRem(14);
		line-height: 20px;
		color: $accent_dark;
	}

	.disclaimer {
		font-weight: 400;
		font-size: toRem(14);
		line-height: 20px;
		color: $accent_dark;
	}

	.pages {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		a {
			font-weight: 400;
			font-size: toRem(16);
			line-height: 24px;
			color: $accent_dark;
			margin-right: 40px;
			display: inline-block;
		}
	}

	.social {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		a {
			margin-left: 24px;
		}

		img {
			display: block;
			transition: 0.25s;
			cursor: pointer;

			&:hover {
				transform: scale(1.25);
			}
		}
	}

	.policy a {
		margin-left: 24px;
	}

	a:hover {
		color: $main_color;
	}
}

.page {
	width: 1100px;
	margin: auto;
	padding-top: 16px;
	padding-bottom: 50px;
	position: relative;
	min-height: calc(100vh - 66px);
	// box-shadow: 0px 5px 20px rgba(150, 163, 181, 0.2);

	& > h1 {
		font-weight: 400;
		font-size: toRem(32);
		line-height: 40px;
		color: black;
		margin: 24px 0px;
	}
}

//////////////////////////////

.job-create.page {
	width: 650px;
	padding-bottom: 60px;

	h2 {
		font-weight: 400;
		font-size: toRem(24);
		line-height: 32px;
		color: black;
		margin-top: 0px;
		margin-bottom: 24px;
	}

	.block {
		padding: 32px 0px;
		border-top: 1px solid rgba(217, 217, 217, 0.48);
	}

	.base-label {
		margin-top: 24px;
		display: block;

		&.mn {
			margin-top: 0px;
		}
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
		transform: scaleX(-1);
	}

	.label-text {
		font-size: 0.875rem;
		line-height: 24px;
		font-weight: 400;
	}

	.input-text {
		font-size: 0.875rem;
		line-height: 24px;
		font-weight: 400;
		padding-left: 5px;
	}

	.hourly-rate {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 18px;

		span {
			font-weight: 600;
		}
	}

	.time-container {
		width: 100px;
	}

	.base-input {
		// margin-bottom: 16px;
		resize: none;

		&:last-child {
			margin-bottom: 0px;
		}
	}

	.tags {
		.base-dropdown {
			display: block;
		}
	}

	.repeat-weekly {
		margin: 20px 0 0 20px;

		span {
			white-space: nowrap;
		}
	}

	// 	// cursor: pointer;

	// 	&.opened .base-select::after {
	// 		transform: rotate(180deg);
	// 	}

	// 	.base-select {
	// 		margin-top: 24px;
	// 	}

	// 	.placeholder {
	// 		opacity: 0.5;
	// 	}

	// 	.menu {
	// 		max-height: 50vh;
	// 		overflow: auto;
	// 	}
	// }
	// }

	.times {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 8px;
	}

	.availability {
		.time-slot {
			margin-top: 10px;
		}
	}

	.overnights {
		margin-bottom: 20px;
		.base-label {
			margin: 2px 0 0 5px;
		}
	}

	.budget {
		h2 {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.on-hover-tip {
			cursor: pointer;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			margin-left: 10px;

			img {
				opacity: 0.7;
			}

			.text-popup {
				visibility: hidden;
				background-color: #555;
				color: #fff;
				text-align: center;
				border-radius: 6px;
				padding: 10px;
				position: absolute;
				z-index: 1;
				opacity: 0;
				transition: opacity 0.3s;
				font-size: 14px;
				width: 400px;
				left: 100%;
				line-height: 1.5;
				top: 50%;
				overflow: hidden;
			}
		}

		.on-hover-tip:hover .text-popup {
			visibility: visible;
			opacity: 1;
		}

		.flex {
			align-items: center;
			justify-content: flex-start;
			margin-top: 16px;
		}

		.price {
			background-color: white;
			border: 1px solid rgb(0, 0, 0, 0.1);
			border-radius: 8px;
			display: flex;
			margin: 0px 20px;
			padding: 10px 20px;
			justify-content: space-between;
			align-items: center;

			img {
				width: 20px;
				height: 20px;
				object-fit: cover;
				margin-left: 5px;
				opacity: 1;
				transition: 0.25s;

				&.unlock {
					opacity: 0.25;
				}
			}

			span {
				margin-right: 5px;
				opacity: 0.25;
			}

			input {
				border: none;
				width: 70px;
				// flex: auto;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				&[type='number'] {
					appearance: textfield;
					-moz-appearance: textfield;
				}
			}
		}
	}

	.error {
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $red;
		margin-top: 20px;
		margin-bottom: 10px;

		b {
			text-transform: capitalize;
		}
	}

	.time-available {
		display: flex;
		justify-content: flex-start;
		margin-top: 20px;
		font-size: 16px;
		font-weight: 600;
	}

	.success {
		color: $green;
	}

	.failure {
		color: $red;
	}

	.submit {
		background: $main_color;
		border-radius: 4px;
		color: white;
		padding: 16px;
		font-weight: 400;
		font-size: toRem(16);
		line-height: 24px;
		width: 100%;
		border: none;
		transition: 0.25s;
		cursor: pointer;

		&:hover {
			background: lighten($color: $main_color, $amount: 5);
		}
	}
}

.home.page {
	.search {
		margin-bottom: 40px;

		form {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			width: 100%;
			height: 70px;
			background-color: white;
			// box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.04);
			// box-shadow: 0px 7px 20px 8px rgb(0, 0, 0, 0.04);
			// box-shadow: 0px 7px 25px 10px rgb(0, 0, 0, 0.04);
			box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.04);
			border-radius: 8px;

			.block {
				width: 210px;
				padding: 10px 30px;
				font-weight: 400;
				font-size: toRem(16);
				line-height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			input {
				border: none;

				&::placeholder {
					color: #a8a8a8;
				}
			}

			.query {
				flex: auto;
				width: auto;
				padding: 5px 30px;
				padding-right: 5px;
				align-self: stretch;

				input {
					flex: auto;
					width: auto;
					height: 100%;
					margin-left: 16px;
				}
			}

			.category {
				width: 300px;
				border-left: 1px solid #eee;
				padding: 0px;
				align-items: stretch;

				.cat {
					font-size: toRem(14);
				}

				.placeholder {
					color: #a8a8a8;
				}

				.base-dropdown {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px 20px;

					img {
						display: block;
						width: 12px;
						margin-left: 10px;
					}
				}
			}
			.date {
				color: #a8a8a8;
				z-index: 100;
				position: relative;
				border-left: 1px solid #eee;
				padding: 8px 10px;
				width: 360px;
				display: flex;
				gap: 6px;
				align-items: center;
				.date-picker {
					width: 115px;
				}

				input {
					position: relative;

					&::-webkit-calendar-picker-indicator {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						cursor: pointer;
						background: transparent;
						z-index: 1;
					}

					&:focus {
						outline: none;
						background: rgba(0, 0, 0, 0.02);
					}
				}
			}

			.city {
				border-left: 1px solid #eee;

				input {
					width: 100%;
					height: 100%;
				}
			}

			.find {
				background-color: $main_color;
				color: white;
				transition: 0.25s;
				width: 170px;
				border: none;
				cursor: pointer;
				border-radius: 0px 4px 4px 0px;

				&:hover {
					background-color: lighten($color: $main_color, $amount: 5);
				}

				&:disabled {
					color: $accent_gray;
					background-color: $accent_medium;
				}
			}
		}

		.sort {
			padding: 10px;
		}
	}

	.list {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 20px;
		// justify-content: flex-start;
		// align-items: flex-start;
		// flex-wrap: wrap;
		// gap: 30px;

		.card {
			background-color: white;
			border-radius: 16px;
			padding: 24px;
			// flex: 0 1 31%;
			// max-width: 33%;
			// position: relative;
			// width: calc((100% / 3) - 20px);
			box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.04);
			transition: 0.25s;
			cursor: pointer;

			&:hover {
				// background-color: darken($color: $accent_light, $amount: 2.5);
				// transform: translateY(-1px);
				box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);

				// .title {
				// 	color: $main_color;
				// }
			}

			.image {
				background-color: #a6b1bf;
				width: 100%;
				height: 170px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 16px;
				position: relative;
				margin-bottom: 42px;
			}

			.creator {
				display: block;
				max-width: 100%;
				// color: white;
				border: 1px solid rgba(0, 0, 0, 0.1);
				background: rgba(0, 0, 0, 0.02);
				// background-color: $main_color;
				border-radius: 12px;
				padding: 8px 16px;
				margin-top: 10px;
				// display: inline-block;
				// box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
				transition: 0.25s;

				// &:hover {
				// 	// transform: scale(1.05);
				// 	// box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);

				// 	// background-color: lighten($color: $main_color, $amount: 5);
				// 	// color: white;
				// }

				.name {
					display: block;
					font-weight: 600;
					font-size: toRem(14);
					line-height: 20px;
					margin-bottom: 4px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					max-width: 100%;
				}

				.location {
					font-weight: 400;
					font-size: toRem(14);
					line-height: 16px;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					img {
						margin-right: 6px;
						display: block;
					}

					span {
						opacity: 0.5;
					}
				}
			}

			.title {
				display: flex;
				flex-direction: row;
				margin-bottom: 5px;

				img {
					width: 44px;
					height: 44px;
					margin-right: 16px;
					margin-bottom: 8px;
					border-radius: 8px;
					overflow: hidden;
					transition: 0.25s;
					object-fit: cover;
				}

				.title-info {
					.name {
						font-weight: 500;
						font-size: toRem(20);
						line-height: 24px;
						transition: 0.25s;
						white-space: nowrap;
					}

					.category {
						font-weight: 400;
						font-size: toRem(11);
						color: $accent_gray;
						display: flex;
						margin-top: 2px;
						width: 100%;
						justify-content: flex-start;
						align-items: center;

						img {
							display: block;
							height: 14px;
							width: 12px;
							margin: 0 5px 0 0;
						}

						span {
							line-height: 12px;
						}
					}
				}
			}

			.description {
				font-weight: 400;
				font-size: toRem(14);
				height: 70px;
				color: $accent_dark;
				text-overflow: ellipsis;
				line-height: 20px;
				margin-bottom: 10px;
				margin-top: 10px;
				padding-top: 10px;
				border-top: 1px solid rgba(0, 0, 0, 0.05);
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}

			.tags {
				max-width: 100%;
				min-height: 20px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				line-height: 0;

				.item {
					padding: 4px 8px;
					// background-color: rgba(0, 0, 0, 0.2);
					background-color: $second_color;
					color: white;
					border-radius: 4px;
					display: inline-block;
					font-weight: 400;
					font-size: toRem(11);
					line-height: 100%;
					margin-right: 4px;
					// margin-bottom: 12px;
				}
			}

			.rate {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding-top: 10px;
				padding-bottom: 0px;
				border-top: 1px solid rgba(0, 0, 0, 0.05);
				margin-top: 16px;
				line-height: 16px;

				span {
					font-weight: 400;
					font-size: toRem(12);
					color: #a6b1bf;
				}

				.price {
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-weight: 500;
					font-size: toRem(16);
					line-height: 11px;

					img {
						height: 20px;
						margin-right: 5px;

						&.lock {
							opacity: 0.8;
						}

						&.unlock {
							opacity: 0.2;
						}
					}
				}
			}
		}
	}

	& > .empty {
		// opacity: 0.3;
		color: $accent_gray;
		text-align: center;
		padding-top: 20px;
		width: 100%;
	}
}

.status-bar {
	border-radius: 5px;
	margin-bottom: 20px;
	background-color: #f0f0f0;

	.message {
		margin: 0;
		font-size: 16px;
		color: #333;
		padding: 10px;
		border-radius: 5px;

		color: #fff;
		font-weight: 500;
		background-color: $main_color;
	}
}

.calendar.page {
	display: flex;
	flex-direction: column;
	margin-top: 30px;

	.react-calendar {
		align-self: center;
		width: 100%;
		border: none;
		background-color: transparent;
	}

	.react-calendar__navigation {
		background-color: #f8f8f8;
		padding: 10px;
		border-bottom: 1px solid #ddd;

		button {
			background-color: transparent;
			border: none;
			color: #000;
			font-size: 1.2em;
			cursor: pointer;

			&:disabled {
				color: #aaa;
			}
		}

		.react-calendar__navigation__label {
			font-weight: bold;
		}
	}

	.react-calendar__month-view__days {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
	}

	.react-calendar__tile {
		width: 100%;
		aspect-ratio: 1 / 1;
		font-size: 18px;
		font-weight: 500;
		background: white;
		border-radius: 4px;
		transition: 0.25s;
		border: 1px solid rgba(0, 0, 0, 0.1);
		cursor: pointer;

		abbr {
			color: #000;
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	.booked {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translateX(-50%);
			width: 6px;
			height: 6px;
			background-color: $red;
			border-radius: 50%;
		}
	}

	.react-calendar__tile--active {
		background-color: #3498db;

		abbr {
			color: #ffffff;
		}
	}

	.react-calendar__month-view__weekdays {
		margin-bottom: 2px;
		background: #3498db;
		color: #ffffff;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		border-radius: 4px;

		abbr {
			text-decoration: none;
		}
	}

	.jobs {
		.job {
			margin-bottom: 16px;
			border-radius: 16px;
			background-color: white;
			box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.1);
			border: 1px solid #e5e6e9;
			padding: 24px 30px;
			position: relative;
			word-wrap: break-word;
			display: flex;
			justify-content: space-between;

			span {
				font-weight: 500;
			}

			.details {
				max-width: 80%;
				word-wrap: break-all;
			}

			.status {
				text-align: end;
			}
		}
	}
}

.job.page {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 30px;

	& > .left {
		width: 720px;
		max-width: 720px;

		.header {
			// padding-left: 44px;
			margin-bottom: 24px;

			.title {
				font-weight: 400;
				font-size: toRem(32);
				line-height: 40px;
				margin-bottom: 8px;
				position: relative;

				.back {
					position: absolute;
					left: -48px;
					top: calc(50% - 16px);
					background-color: $accent_medium;
					width: 32px;
					height: 32px;
					border-radius: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					transition: 0.25s;

					&:hover {
						background-color: darken($color: $accent_medium, $amount: 5);
					}

					img {
						display: block;
					}
				}
			}

			.user {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;

				.name {
					font-weight: 500;
					font-size: toRem(16);
					line-height: 20px;
					color: $main_color;
				}

				.location {
					font-weight: 400;
					font-size: toRem(14);
					line-height: 18px;
					margin-left: 10px;
				}
			}
		}

		.label {
			font-weight: 500;
			font-size: toRem(20);
			line-height: 24px;
			color: #3a4554;
			margin-bottom: 16px;
		}

		.description {
			margin-bottom: 28px;

			.content {
				font-weight: 400;
				font-size: toRem(16);
				line-height: 24px;
				color: #76879d;
			}
		}

		.tags {
			.item {
				padding: 8px 16px;
				background-color: $second_color;
				color: white;
				border-radius: 4px;
				display: inline-block;
				font-weight: 400;
				font-size: toRem(16);
				line-height: 100%;
				margin-right: 8px;
				margin-bottom: 12px;
				cursor: pointer;
				transition: 0.25s;

				&:hover {
					background-color: darken($color: $second_color, $amount: 5);
				}
			}
		}
	}

	& > .right {
		width: 350px;

		.box {
			padding: 24px;
			border: 1px solid #dadbdd;
			background-color: white;
			border-radius: 16px;
			margin-bottom: 16px;
			position: relative;

			&.applied {
				.title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: toRem(16);
				}

				.price {
					font-weight: 600;
					font-size: toRem(18);
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 20px;
						margin-right: 5px;

						&.unlock {
							opacity: 0.2;
						}
					}
				}

				.message {
					width: 100%;
					background-color: #eef0f2;
					border-radius: 8px;
					padding: 16px;
					font-weight: 400;
					font-size: toRem(12);
					line-height: 16px;
					margin-top: 16px;
					color: #8c97a6;
				}
			}

			.title {
				font-weight: 500;
				font-size: toRem(14);
				line-height: 20px;
			}

			.info {
				font-weight: 400;
				font-size: toRem(14);
				line-height: 20px;
				color: $accent_gray;
				margin-top: 8px;
			}

			.status {
				margin-top: 8px;
				padding: 16px;
				border-radius: 10px;
				color: #f0f0f0;
				background-color: #00c2ff;
			}

			.btn {
				display: block;
				width: 100%;
				border: none;
				background-color: $main_color;
				color: white;
				border-radius: 4px;
				font-weight: 400;
				font-size: toRem(16);
				line-height: 24px;
				margin-top: 16px;
				cursor: pointer;
				transition: 0.25s;

				&:hover {
					background-color: lighten($color: $main_color, $amount: 5);
				}

				&:disabled {
					background-color: $accent_medium;
					color: $accent_gray;
				}
			}

			a[disabled] {
				pointer-events: none;
				background-color: $accent_medium;
				color: $accent_gray;
			}

			.budget {
				margin-top: 22px;

				.item {
					margin-bottom: 16px;
					padding-bottom: 16px;
					border-bottom: 1px solid rgba(166, 177, 191, 0.21);
				}

				.price {
					font-weight: 500;
					font-size: toRem(20);
					line-height: 24px;
					color: black;
				}

				.desc {
					margin-top: 12px;
					margin-left: 24px;
					font-weight: 400;
					font-size: toRem(14);
					line-height: 24px;
					color: $accent_dark;
				}
			}

			.btn {
				padding: 12px;
				text-align: center;
				font-weight: 400;
				font-size: toRem(16);
				line-height: 24px;
				border-radius: 4px;
				cursor: pointer;
				transition: 0.25s;

				&.apply {
					display: block;
					width: 100%;
					border: none;
					color: white;
					background-color: $main_color;
					margin-bottom: 8px;

					&:hover {
						background-color: lighten($color: $main_color, $amount: 5);
					}
				}

				&.message {
					color: $accent_dark;
					border: 1px solid rgba(118, 135, 157, 0.3);

					&:hover {
						background-color: darken($color: white, $amount: 5);
					}
				}
			}

			.paypal-connect {
				position: absolute;
				top: 4px;
				left: 4px;
				right: 4px;
				bottom: 4px;
				padding: 20px;
				background-color: rgba(255, 255, 255, 0.9);
				backdrop-filter: blur(4px);
				text-align: center;

				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				align-content: center;

				.loader {
					left: 5px;
					right: 5px;
					background-color: rgba(255, 255, 255, 0.9);
				}
			}
		}
	}

	.overlay {
		justify-content: flex-end;

		&.show .order {
			transform: translateX(0%);
		}
	}

	.order {
		width: 550px;
		height: 100%;
		max-height: 100%;
		padding: 32px;
		background-color: white;
		height: 100%;
		transform: translateX(100%);
		transition: 0.5s;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;

		.details {
			flex: 1;
		}

		.bottom {
			.error {
				color: #e74c3c;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: toRem(14);
				margin-bottom: 8px;

				b {
					margin-right: 6px;
					text-transform: capitalize;
				}
			}

			.send {
				display: block;
				width: 100%;
				border: none;
				font-weight: 400;
				font-size: toRem(16);
				line-height: 24px;
				padding: 16px;
				text-align: center;
				background-color: $main_color;
				color: white;
				border-radius: 4px;
				cursor: pointer;
				transition: 0.25s;

				&:hover {
					background-color: lighten($color: $main_color, $amount: 5);
				}
			}
		}

		h2 {
			font-weight: 400;
			font-size: toRem(24);
			line-height: 32px;
			color: #3a4554;
			margin: 0px;
			margin-bottom: 32px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			img {
				transition: 0.25s;
				cursor: pointer;

				&:hover {
					transform: scale(1.25);
				}
			}
		}

		.row {
			gap: 24px;
			margin-bottom: 24px;
		}

		.box {
			border: 1px solid #d9d9d9;
			border-radius: 16px;
			padding: 24px;
			margin-bottom: 24px;

			&.half {
				flex: 1;
				margin-bottom: 0px;
			}

			h3 {
				font-weight: 500;
				font-size: toRem(24);
				line-height: 32px;
				margin: 0px;
				margin-bottom: 8px;
			}

			.title {
				font-weight: 500;
				font-size: toRem(16);
				line-height: 100%;
				margin-bottom: 8px;
			}

			.info {
				font-weight: 400;
				font-size: toRem(14);
				line-height: 20px;
				color: $accent_gray;
				margin-top: 8px;
			}

			p {
				font-weight: 400;
				font-size: toRem(14);
				line-height: 24px;
				color: $accent_dark;
				margin: 0px;
				margin-bottom: 8px;
			}

			.message {
				position: relative;

				textarea {
					display: block;
					width: 100%;
					font-weight: 400;
					font-size: toRem(14);
					line-height: 24px;
					padding: 16px;
					border-radius: 4px;
					border: none;
					background-color: $accent_light;
					resize: none;
				}

				.chars {
					position: absolute;
					right: 12px;
					bottom: 12px;
					font-weight: 500;
					font-size: toRem(12);
					line-height: 16px;
					color: #99a7ba;
				}
			}
		}

		.price {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 0px;
			font-weight: 500;
			font-size: toRem(24);
			line-height: 32px;
			color: black;
			cursor: text;

			.left {
				flex: auto;
				padding-left: 24px;
			}

			.right {
				padding: 24px;
				border-left: 1px solid #d9d9d9;

				input {
					width: 85px;
					border: none;
					background-color: white !important;
				}
			}
		}
	}
}

.paypal-pay-button {
	max-height: 650px;
	overflow-y: auto;
}

.myorders.page {
	width: 900px;

	.job {
		margin-bottom: 16px;
		border-radius: 16px;
		// background-color: $accent_light;
		background-color: white;
		box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.1);
		border: 1px solid $accent_medium;
		padding: 24px 30px;
		position: relative;

		.title {
			display: block;
			font-weight: 500;
			font-size: toRem(20);
			line-height: 24px;
			margin-bottom: 16px;
			// padding-bottom: 8px;
			// border-bottom: 1px solid rgba(0, 0, 0, 0.05);

			// a {
			// 	color: $accent_dark;
			// 	transition: 0.25s;

			// 	&:hover {
			// 		color: black;
			// 	}
			// }
		}

		.info {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.cols {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				width: 540px;
			}

			.block {
				flex: 1 1 25%;

				.name {
					font-weight: 400;
					font-size: toRem(14);
					line-height: 16px;
					color: $accent_dark;
					margin-bottom: 4px;
				}

				.value {
					font-weight: 600;
					font-size: toRem(14);
					line-height: 20px;
					color: black;
					text-transform: capitalize;
				}
			}
		}

		.buttons {
			min-width: 330px;
			text-align: right;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: center;
		}

		.btn {
			width: 30px;
			height: 30px;
			background-color: $main_color;
			border-radius: 4px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			color: white;
			font-size: toRem(14);
			margin-left: 5px;
			border: none;
			transition: 0.25s;
			cursor: pointer;

			// width: calc(50% - 5px);
			padding: 0px 10px;
			margin-top: 10px;

			&.text {
				width: 125px;
			}

			&.text-large {
				width: 132px;
			}

			span {
				margin-left: 5px;
			}

			img {
				display: block;
				width: 18px;
			}

			&:hover {
				background-color: lighten($color: $main_color, $amount: 5);
			}

			&:disabled {
				background-color: lighten($color: $accent_gray, $amount: 15);
			}
		}

		.btn-icon {
			padding: 0;
		}
	}
}

.bids.page {
	width: 900px;

	& > h1 {
		margin-bottom: 0px;
	}

	.for {
		margin: 0px;
		color: $accent_gray;
		font-size: toRem(12);

		a {
			font-weight: 600;
			margin-bottom: 24px;
		}
	}

	.base-tabs {
		margin-top: 32px;
		padding-bottom: 50px;
	}

	.bid {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 24px;

		.avatar {
			// flex: 1 1;
			flex: 0 100px;
			min-width: 100px;
			height: 100px;
			background-color: $accent_gray;
			border-radius: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.content {
			flex: auto;
			margin: 0px 24px;
			// flex-grow: 0;
			// padding-top: 20px;

			.title {
				display: block;
				font-weight: 500;
				font-size: toRem(20);
				line-height: 24px;
			}

			.info {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin: 8px 0px;

				.item {
					font-size: toRem(14);
					line-height: 20px;
					color: #79899c;
					margin-right: 20px;

					b {
						color: black;
						font-weight: 600;
						margin-left: 5px;
					}
				}
			}

			.more {
				.message {
					width: 100%;
					background-color: white;
					border-radius: 12px;
					padding: 16px;
					font-weight: 400;
					font-size: toRem(12);
					line-height: 16px;
					color: #8c97a6;
					border: 1px solid rgba(0, 0, 0, 0.05);
				}

				.buttons {
					display: flex;
					max-width: 235px;
					margin-left: auto;
					margin-top: 10px;
					justify-content: flex-end;
					align-items: center;

					.btn {
						display: flex;
						justify-content: center;
						align-items: center;
						flex: 5;
						height: 40px;
						margin: 0px 4px;
						color: white;
						background-color: $accent_dark;
						border-radius: 4px;
						border: none;
						text-align: center;
						font-weight: 400;
						font-size: toRem(14);
						line-height: 24px;
						cursor: pointer;
						transition: 0.25s;

						&:hover {
							background-color: darken($color: $accent_dark, $amount: 5);
						}

						&.bordered {
							background-color: transparent;
							color: $accent_dark;
							border: 1px solid $accent_dark;

							&:hover {
								background-color: darken($color: white, $amount: 5);
							}
						}

						&.message {
							// width: 100%;
							flex: 1;
							width: 40px;
							min-width: 40px;

							img {
								width: 20px;
								display: block;
							}

							span {
								margin-left: 10px;
							}
						}

						&:disabled {
							// color: $accent_dark;
							flex: 8;
							background-color: #e9ebee !important;
							border: none;
						}
					}
				}

				.large.buttons {
					.btn {
						flex: none;
						&.message {
							width: auto;
						}

						&:disabled {
							flex: none;
						}
					}
				}

				.review {
					justify-self: flex-end;
					width: 120px;
					height: 30px;
					background-color: #184fff;
					border-radius: 4px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					color: white;
					font-size: 0.875rem;
					margin-left: 5px;
					border: none;
					transition: 0.25s;
					cursor: pointer;
					padding: 0px 10px;
					margin-top: 10px;
					img {
						display: block;
						width: 18px;
					}
					&:disabled {
						background-color: #d3d8df;
					}
				}
			}
		}
	}

	.base-modal {
		.paid {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 30px;
				display: block;
				margin-right: 10px;
			}

			span {
				color: $green;
				font-weight: 500;
			}
		}

		p.small {
			font-size: 14px;
			font-weight: 300;
		}
	}
}

.mybids.page {
	width: 800px;

	.bid {
		padding: 24px;
		border-radius: 16px;
		border: 1px solid rgb(0, 0, 0, 0.1);
		background-color: white;
		box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.1);

		.content {
			margin: 0px;

			.dispute {
				margin-top: 10px;
				font-weight: 500;
			}

			.info {
				justify-content: space-between;

				.item {
					margin-right: 0px;
				}
			}
		}

		.buttons {
			min-width: 55px;
			max-width: 55px;
			margin-left: 10px;

			.btn {
				margin: 0px;
				height: 48px;
			}
		}
	}
}

.profile-user {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;

	.avatar {
		width: 100px;
		height: 100px;
		// background: $second_color;
		border-radius: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.info {
		margin-left: 24px;
		flex: auto;

		.name {
			font-weight: 500;
			font-size: toRem(24);
			line-height: 100%;

			&.big {
				font-weight: 400;
				font-size: toRem(32);
				line-height: 40px;
			}
		}

		.type {
			font-weight: 400;
			font-size: toRem(16);
			line-height: 24px;
			color: $accent_dark;
			text-transform: capitalize;
			margin-top: 2px;
		}

		.location {
			color: $accent_gray;
			font-weight: 400;
			font-size: toRem(16);
			line-height: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-top: 4px;

			img {
				margin-right: 8px;
				display: block;
			}
		}
	}

	.button {
		font-weight: 400;
		font-size: toRem(14);
		line-height: 20px;
		color: $accent_dark;
		background: $accent_medium;
		border-radius: 4px;
		text-align: center;
		width: 160px;
		padding: 8px;
		transition: 0.25s;
		cursor: pointer;

		&:hover {
			background: darken($color: $accent_medium, $amount: 5);
		}
	}
}

.profile-summary {
	width: 360px;
	min-width: 360px;
	background-color: white;
	box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	padding: 24px;

	.reviews {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.percent {
			font-weight: 500;
			font-size: 48px;
			line-height: 100%;
			margin-right: 16px;

			&.hide {
				// opacity: 0.2;
				color: $accent_medium;
			}
		}

		.count {
			font-weight: 400;
			font-size: toRem(16);
			line-height: 100%;
			flex: auto;

			.value {
				color: $accent_gray;
				margin-top: 4px;
			}
		}
	}

	.registration {
		padding: 20px 0px;
		margin: 20px 0px;
		border-top: 1px solid $accent_light;
		border-bottom: 1px solid $accent_light;
		// display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-weight: 500;
			font-size: toRem(16);
			line-height: 100%;
		}

		.date {
			font-weight: 400;
			font-size: toRem(14);
			line-height: 16px;
			color: $accent_gray;
			margin-top: 8px;
		}
	}

	.rating {
		display: flex;
		justify-content: center;
		align-items: center;

		.stars {
			display: flex;
			justify-content: center;
			align-items: center;
			// display: grid;
			// grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			// gap: 6px;

			img {
				display: block;
				width: 20px;
				margin: 0px 5px;

				&.opacity {
					opacity: 0.3;
				}
			}
		}

		.count {
			font-weight: 600;
			font-size: toRem(14);
			line-height: 20px;
			margin-left: 18px;

			span {
				opacity: 0.3;
				margin-left: 2px;
			}
		}
	}
}

.profile.page {
	// width: 900px;
	padding-top: 40px;

	.sides {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		& > .left {
			flex: auto;

			.profile-user {
				margin-bottom: 0px;
			}

			.more {
				margin-top: 12px;
				margin-left: 124px;

				.description {
					font-weight: 400;
					font-size: toRem(14);
					line-height: 24px;
					color: $accent_gray;
				}

				.tags {
					margin-top: 24px;
					padding-top: 24px;
					border-top: 1px solid $accent_light;

					.item {
						padding: 10px 16px;
						background-color: $second_color;
						color: white;
						border-radius: 4px;
						display: inline-block;
						font-weight: 400;
						font-size: toRem(16);
						line-height: 100%;
						margin-right: 8px;
						margin-bottom: 12px;
						cursor: pointer;
						transition: 0.25s;

						&:hover {
							background-color: darken($color: $second_color, $amount: 5);
						}
					}
				}
			}

			.about {
				margin-left: 124px;
				margin-top: 40px;

				background: white;
				border-radius: 16px;
				padding: 30px;

				.base-tabs {
					.header {
						background-color: transparent;
						border: none;
						box-shadow: none;
						display: flex;
						margin-left: auto;
						margin-right: auto;
						border-radius: 16px;
						overflow: hidden;
					}

					.tab {
						min-width: 150px;
						text-align: center;
						// border-bottom: 2px solid rgba(0, 0, 0, 0.05);
						background-color: rgba(0, 0, 0, 0.05);
						margin: 0px;

						&:first-child {
							border-radius: 8px 0px 0px 8px;
						}

						&:last-child {
							border-radius: 0px 8px 8px 0px;
						}

						&:hover {
							background-color: rgba(0, 0, 0, 0.1);
						}

						&.selected {
							background-color: $main_color;
						}
					}
				}

				.resume {
					h2 {
						font-weight: 500;
						font-size: toRem(20);
						line-height: 24px;
						margin: 0px;
						margin-top: 28px;
						margin-bottom: 12px;
						border-bottom: 1px solid $accent_medium;
						padding-bottom: 8px;
					}

					p {
						font-weight: 400;
						font-size: toRem(14);
						line-height: 24px;
						color: $accent_gray;
						margin: 0px;

						b {
							font-weight: 600;
						}
					}

					.cost {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 12px;
						font-weight: 400;
						font-size: toRem(16);
						line-height: 100%;

						&:last-child {
							margin-bottom: 0px;
						}

						.dots {
							flex: auto;
							border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
							margin: 0px 20px;
						}
					}

					.availability-day {
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						color: #a6b1bf;
					}

					.day-name {
						margin-right: 10px;
						min-width: 100px;
					}

					.time-slot {
						background-color: #aeb7c1;
						color: #fff;
						padding: 2px 6px;
						border-radius: 4px;
						margin-right: 5px;
						font-size: 14px;
						display: inline-block;
						white-space: nowrap;
					}
				}

				.list {
					.item {
						display: block;
						border-radius: 8px;
						border: 1px solid rgb(0, 0, 0, 0.1);
						background-color: white;
						outline: 1px solid rgba(0, 0, 0, 0);
						padding: 20px;
						margin-bottom: 16px;
						transition: 0.25s;
						// cursor: pointer;

						&:hover {
							// border: 1px solid rgb(0, 0, 0, 0.1);
							background-color: rgb(0, 0, 0, 0.03);
						}

						&:last-child {
							margin-bottom: 0px;
						}

						.info {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.left {
								flex: auto;
							}
						}

						.title {
							font-weight: 500;
							font-size: toRem(18);
							line-height: 100%;
						}

						.subtitle {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							margin-top: 5px;

							font-weight: 500;
							font-size: toRem(12);
							line-height: 100%;
							opacity: 0.7;

							img {
								display: block;
								height: 14px;
								margin-right: 5px;
							}

							div {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								margin-right: 10px;
							}
						}

						.description {
							font-weight: 400;
							font-size: toRem(12);
							line-height: 18px;
							color: #76879d;
							margin-top: 8px;

							overflow: hidden;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							text-overflow: ellipsis;
							max-height: 40px;
						}

						.right {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.price {
								font-weight: 500;
								font-size: toRem(20);
								line-height: 100%;
							}

							.star {
								width: 14px;
								display: block;
								margin-right: 3px;

								&.opacity {
									opacity: 0.3;
								}
							}

							.rate {
								font-weight: bold;
								line-height: 14px;
								font-size: toRem(14);
								margin-left: 10px;
							}

							.lock {
								display: block;
								height: 24px;
								margin-right: 5px;

								&.unlock {
									opacity: 0.2;
								}
							}
						}
					}
				}
			}
		}

		& > .right {
			margin-left: 30px;
			position: sticky;
			top: 30px;

			.rate {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-radius: 16px;
				padding: 16px 24px;
				margin: 24px 0px;
				// border: 1px solid $accent_medium;
				background-color: $accent_light;
				// box-shadow: 0px 8px 50px rgb(0, 0, 0, 0.05);

				font-weight: 400;
				font-size: toRem(16);
				line-height: 24px;

				div {
					flex: 1;
				}

				.title {
					color: $accent_dark;
				}

				.value {
					text-align: right;
					font-weight: 500;
					border-left: 1px solid rgba(0, 0, 0, 0.1);

					small {
						font-size: toRem(11);
						color: $accent_dark;
					}
				}
			}

			button {
				width: 100%;
				display: block;
				font-weight: 400;
				font-size: toRem(16);
				line-height: 24px;
				color: white;
				padding: 16px;
				border-radius: 8px;
				border: none;
				transition: 0.25s;
				cursor: pointer;
				margin-top: 10px;
			}

			.hire {
				background-color: $accent_dark;

				&:hover {
					background-color: darken($color: $accent_dark, $amount: 5);
				}
			}

			.message {
				background-color: $main_color;
				color: white;

				&:hover {
					background-color: lighten($color: $main_color, $amount: 5);
				}
			}
		}
	}
}

.profile-edit.page {
	// width: 900px;
	padding-top: 40px;

	.base-tabs {
		.header {
			justify-content: flex-start;
			overflow: hidden;

			.tab {
				padding: 10px 14px;
				font-weight: 400;
				font-size: toRem(14);
				line-height: 24px;
				text-align: center;
				margin: 0px;
			}
		}
	}

	.edit-tab {
		.base-label {
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
			margin-bottom: 8px;
			margin-top: 24px;
		}

		.base-input {
			padding: 12px 16px;
			border: 1px solid transparent;
			transition: border-color 0.3s ease;
			position: relative;
		}

		.saved {
			border-color: rgb(29, 208, 29);
		}

		.input-container {
			display: flex;
			flex-direction: column;
			position: relative;

			.visible {
				color: rgb(29, 208, 29);
			}

			.hidden {
				color: $red;
			}

			.btn {
				width: 150px;
			}
		}

		.divider {
			width: 100%;
			height: 60px;
		}

		.input-container.saved {
			.base-edit textarea {
				border-color: rgb(29, 208, 29);
			}
		}

		.email-message {
			margin-top: 30px;
		}

		.saved-message {
			position: absolute;
			margin-top: 2px;
			z-index: 1;
			top: 100%;
			opacity: 0;
			transition: opacity 0.9s ease;
			color: rgb(29, 208, 29);
		}

		.show {
			opacity: 1;
		}
		.confirmation-container {
			.confirm-code {
				p {
					display: none;
				}
				.error {
					margin-top: 10px;
					color: red;
				}
				.resend-container {
					.timer {
						margin-top: 10px;
						font-size: 14px;
					}
					.resend {
						margin-top: 10px;
						display: flex;
						flex-direction: row;
						font-size: 14px;
						color: $main_color;
						button {
							margin-left: auto;
							border: none;
							background-color: transparent;
							margin-top: 0;
							cursor: pointer;
							font-weight: 500;
						}
					}
				}
			}
			button {
				margin-top: 20px;
			}
		}

		.after-about {
			margin: 0px;
			margin-top: 24px;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
			color: $accent_dark;
		}

		.bottom {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-top: 48px;

			.error {
				flex: auto;
				color: $red;

				b {
					text-transform: capitalize;
				}
			}

			.save {
				width: 180px;
				background-color: $main_color;
				color: white;
				border-radius: 4px;
				padding: 8px;
				font-weight: 400;
				font-size: toRem(14);
				line-height: 24px;
				outline: none;
				border: none;
				margin-right: 20px;
				transition: 0.25s;
				cursor: pointer;

				&:hover {
					background-color: lighten($color: $main_color, $amount: 5);
				}

				&:disabled {
					background-color: $accent_medium;
				}
			}
		}

		.phone-input {
			input {
				padding: 6px;
				border: 1px solid transparent;
				border-radius: 4px;
			}
		}

		.save {
			input {
				padding: 6px;
				border: 1px solid rgb(29, 208, 29);
				border-radius: 4px;
			}
		}

		&.general {
			.avatar {
				display: flex;
				justify-content: space-between;
				align-items: stretch;

				.upload {
					background-color: $main_color;
					margin-left: 10px;
					width: 150px;

					&:hover {
						background-color: lighten($color: $main_color, $amount: 5);
					}

					&:disabled {
						background-color: $accent_medium;
					}
				}
			}

			.btn {
				background-color: $main_color;
				color: #f0f0f0;
				border-radius: 4px;
				padding: 6px;
				margin: 2px;
				border: none;
				cursor: pointer;
			}

			.phone-container {
				display: flex;
			}

			.phone-confirmation {
				align-self: flex-end;
				width: 205px;
				height: 35px;
				margin: 0 20px;
				&:disabled {
					background-color: gray;
				}
			}

			.address-confirmation {
				align-self: flex-end;
				height: 42px;
				&:disabled {
					background-color: gray;
				}
			}

			.location-info {
				margin-top: 20px;
			}

			.google-event {
				display: flex;
				align-items: center;

				input {
					margin-right: 10px;
				}
			}

			.csv {
				padding: 6px 12px;
				margin-top: 10px;
			}
		}

		&.work {
			.category-title {
				font-weight: 500;
				font-size: toRem(24);
				line-height: 100%;
				margin: 0px;
				margin-top: 32px;
				margin-bottom: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.section-title {
				margin: 0px;
			}

			.rate {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				input {
					width: 80px;
					margin-right: 16px;
				}

				span {
					font-weight: 400;
					font-size: toRem(14);
					line-height: 24px;
					color: $accent_dark;
				}
			}

			.overnight-container {
				display: flex;
				.base-label {
					margin: 0 0 0 8px;
				}
			}

			.list {
				.length {
					font-size: toRem(14);
					color: $accent_dark;
				}

				.item {
					margin-top: 8px;
					display: flex;
					justify-content: flex-start;
					align-items: stretch;

					.title {
						flex: auto;
						display: flex;
						align-items: center;
					}

					.price {
						margin-left: 8px;
						width: 90px;
					}

					.remove {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 40px;
						margin-left: 8px;
						background-color: $red;
						transition: 0.25s;

						&:hover {
							background-color: darken($color: $red, $amount: 5);
						}

						img {
							display: block;
							height: 20px;
						}
					}

					&.category {
						display: flex;
						font-weight: 400;
						font-size: toRem(14);
						line-height: 24px;
						color: $accent_dark;

						.title img {
							margin-right: 8px;
						}
					}
				}
			}

			.add {
				min-width: 120px;
				background-color: $main_color;

				&:hover {
					background-color: lighten($color: $main_color, $amount: 10);
				}
			}

			.cancel {
				min-width: 120px;
				background-color: $red;

				&:hover {
					background-color: lighten($color: $red, $amount: 10);
				}
			}

			.save {
				min-width: 120px;
				background-color: $main_color;

				&:hover {
					background-color: lighten($color: $main_color, $amount: 10);
				}
			}

			.base-dropdown {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}

			.error {
				color: red !important;
				padding-bottom: 10px;
			}
		}

		.desc {
			font-size: 0.875rem;
			line-height: 24px;
			font-weight: 400;
			color: black;
		}

		.background-check-status {
			font-weight: 700;
		}

		.background-check-url {
			color: #007bff;
			cursor: pointer;
			text-decoration: underline;
		}

		.column-flex {
			display: flex;
			flex-direction: column;
		}

		.center {
			align-items: center;
		}

		.submit {
			background: $main_color;
			border-radius: 4px;
			color: white;
			padding: 12px;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
			border: none;
			transition: 0.25s;
			cursor: pointer;

			&:hover {
				background: lighten($color: $main_color, $amount: 5);
			}
		}
	}

	.payments-tab {
		height: 460px;
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		.left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: stretch;

			.box {
				width: 350px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex: 1;
				text-align: center;
				// border: 1px solid $accent_medium;
				box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.1);
				background-color: white;
				border-radius: 8px;
				margin-bottom: 30px;
				padding: 32px;

				.title {
					font-size: toRem(18);
					opacity: 0.5;
				}

				.balance {
					font-size: toRem(32);
					line-height: 32px;
					margin-top: 5px;

					.deposit {
						// font-size: toRem(22);
						color: $green;
					}

					.payout {
						color: $accent_gray;
					}

					.fee {
						display: block;
						font-size: toRem(12);
						font-weight: 500;
						line-height: 12px;
						margin-top: 7px;
						opacity: 0.3;
					}
				}
			}

			.form {
				width: 350px;
				position: relative;

				.loader {
					background-color: rgba(255, 255, 255, 0.4);
					backdrop-filter: blur(3px);
					top: -10px;
					left: -10px;
					right: -10px;
					bottom: -10px;
					padding-bottom: 40px;

					.spiner::after {
						border-color: white;
						border-top-color: transparent;
					}
				}

				.base-completed {
					top: -10px;
					bottom: -10px;

					.icon {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}

				.test {
					position: absolute;
					top: 100%;
					left: 0px;
					right: 0px;
					opacity: 0.2;
					font-weight: 500;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: toRem(12);
					padding: 15px;
				}
			}

			.card {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: stretch;
				width: 350px;
				height: 215px;
				border-radius: 8px;
				box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.1);
				// background: linear-gradient(125deg, #dbb25e, #6b4e29);
				background: linear-gradient(125deg, #488ecb, #3d3776);
				padding: 30px;
				color: white;

				&.paypal {
					// justify-content: center;
					justify-content: flex-start;
					padding-top: 65px;
				}

				&.skeleton {
					justify-content: center;

					h2 {
						text-align: center;
						margin: 0px;
						font-size: toRem(16);
						font-weight: 400;
						margin-bottom: 10px;
					}

					p {
						text-align: center;
						font-size: toRem(12);
						margin: 0px;
						margin-top: 10px;
						opacity: 0.5;
					}

					.value {
						display: flex;
						justify-content: center;
						align-items: stretch;

						input {
							width: 150px;
							margin-right: 10px;
						}

						button:disabled {
							opacity: 0.25;
						}
					}
				}

				input {
					color: black;
				}

				.title {
					font-size: toRem(22);
					font-weight: 500;
				}

				.number {
					width: 100%;

					.hidden {
						font-size: toRem(18);
						word-spacing: 4px;
					}
				}

				.name {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					font-size: toRem(12);
					color: rgba(255, 255, 255, 0.5);
				}

				.pay {
					min-width: 70px;
				}
			}
		}

		.history {
			flex: auto;
			margin-left: 30px;
			background-color: white;
			box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.1);
			border-radius: 8px;
			padding: 16px 24px;
			overflow: auto;

			.empty {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 8px 0px;
				font-size: toRem(12);
				line-height: 12px;
				border-bottom: 1px solid $accent_medium;

				div {
					flex: 1;
					width: 25%;
					text-align: center;
				}

				// font-family: monospace;

				&:last-child {
					border-bottom: none;
				}

				// &:nth-child(2n) {
				// 	background-color: rgba(0, 0, 0, 0.025);
				// }

				.id,
				.date {
					opacity: 0.5;
					font-family: monospace;
				}

				.id {
					text-align: left;
				}

				.date {
					text-align: right;
				}

				.type {
					text-transform: capitalize;
				}

				.amount {
					font-weight: 500;

					&.green {
						color: $green;
					}

					&.blue {
						color: $blue;
					}

					&.red {
						color: $red;
					}
				}
			}
		}
	}

	.time-slots-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}

.chats.page {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	height: calc(100vh - 66px);

	.dialogs {
		width: 360px;
		min-width: 360px;
		background-color: white;
		border-radius: 8px;
		margin-right: 24px;
		box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.08);

		h2 {
			margin: 0px;
			padding: 24px;
			font-size: toRem(24);
			font-weight: 600;
		}

		.item {
			padding: 16px 16px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// border-top: 1px solid $accent_medium;
			transition: background-color 0.25s;
			cursor: pointer;

			&:hover {
				background-color: rgba(0, 0, 0, 0.03);
			}

			&.current {
				background-color: $main_color;
				color: white;

				.budge {
					background-color: white !important;
					color: $accent_dark !important;
				}
			}

			.avatar {
				display: block;
				width: 48px;
				min-width: 48px;
				margin-right: 12px;
				border-radius: 100%;
				box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.05);
			}

			.info {
				flex: auto;
				max-width: calc(100% - 60px);
				position: relative;
				top: 2px;

				.flex {
					display: flex;
					justify-content: space-between;
					align-items: center;

					&.bottom {
						margin-top: 2px;
					}

					.name {
						font-weight: 500;
						font-size: toRem(16);
						line-height: 100%;
					}

					.date {
						font-weight: 400;
						font-size: toRem(14);
						line-height: 100%;
						opacity: 0.5;
					}

					.message {
						font-weight: 300;
						font-size: toRem(16);
						line-height: 100%;
						opacity: 0.8;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}

					.budge {
						// margin-left: 10px;
						border-radius: 100%;
						min-width: 20px;
						width: 20px;
						height: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: white;
						font-weight: 400;
						font-size: toRem(11);
						line-height: 100%;
						background-color: $accent_gray;
					}
				}
			}
		}
	}

	.chat {
		flex: auto;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.08);

		&.center {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			img {
				display: block;
				margin: auto;
			}

			.title {
				font-weight: 500;
				font-size: toRem(20);
				line-height: 100%;
				margin-top: 20px;
				color: $accent_gray;
			}
		}

		&.single {
			padding: 24px;
			display: flex;
			flex-direction: column;
		}

		.messages {
			flex: auto;
			max-height: calc(100% - 60px);
			overflow: auto;

			.item {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				margin-top: 20px;

				&.my {
					flex-direction: row-reverse;

					.content {
						.cloud {
							background-color: $accent_gray;
							color: white;
						}

						.info {
							flex-direction: row-reverse;
						}
					}
				}

				&:first-child {
					margin-top: 0px;
				}

				.avatar {
					width: 40px;
					height: 40px;
					object-fit: cover;
					border-radius: 100%;
					margin: 0px 10px;
					margin-bottom: 20px;
				}

				.content {
					// margin: 0px 16px;
					max-width: 70%;

					.cloud {
						background-color: $accent_medium;
						// border-radius: 8px 8px 8px 0px;
						border-radius: 8px;
						padding: 16px;
						font-size: toRem(14);
						font-weight: 400;
						line-height: 22px;
					}

					.info {
						display: flex;
						justify-content: space-between;
						gap: 10px;
						align-items: center;
						width: 100%;
						color: rgb(0, 0, 0, 0.35);
						font-size: toRem(11);
						font-weight: 500;
						margin-top: 3px;
						padding: 0px 5px;
					}
				}
			}

			.system {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;
				margin-bottom: 20px;
				color: rgba(0, 0, 0, 0.583);
				font-size: toRem(11);
				font-weight: 500;
				padding: 0px 5px;

				.content {
					.cloud {
						background-color: #f5f5f5;
					}

					.info {
						justify-content: center;
					}
				}
			}
		}

		.bottom {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			margin-top: 20px;

			input,
			button {
				font-weight: 400;
				font-size: toRem(14);
				line-height: 24px;
				border: none;
				padding: 8px 20px;
			}

			input {
				background-color: $accent_light;
				border-radius: 4px 0px 0px 4px;
				flex: auto;
			}

			button {
				background-color: $main_color;
				color: white;
				border-radius: 0px 4px 4px 0px;
				min-width: 100px;
				cursor: pointer;
				transition: 0.25s;

				&:hover {
					background-color: darken($color: $accent_gray, $amount: 5);
				}
			}

			.disabled {
				background-color: darken($color: $accent_gray, $amount: 5);
				cursor: default;
			}
		}
	}
}

.email-unsubscribe {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;

	.unsubscribe-container {
		text-align: center;
		background: #ffffff;
		padding: 40px;
		border-radius: 10px;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		max-width: 400px;
		width: 100%;

		h1 {
			font-size: 24px;
			margin-bottom: 20px;
			color: #333333;
		}

		p {
			font-size: 16px;
			margin-bottom: 30px;
			color: #666666;
		}

		.unsubscribe-button {
			background-color: #ff4d4f;
			color: #ffffff;
			border: none;
			padding: 10px 20px;
			border-radius: 5px;
			font-size: 16px;
			cursor: pointer;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: #e04344;
			}
		}

		.go-back {
			display: inline-block;
			margin-top: 20px;
			font-size: 14px;
			color: #1890ff;
			text-decoration: none;
			transition: color 0.3s ease;
			cursor: pointer;
			background-color: transparent;
			border: none;
			&:hover {
				color: $main_color;
			}
		}
		.popup-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0, 0, 0, 0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1000;

			.popup-message {
				background-color: #fff;
				padding: 20px 30px;
				border-radius: 8px;
				text-align: center;
				box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
				font-size: 16px;
				max-width: 90%;
				color: #333;
			}
		}
	}
}

.policy.page {
	width: 750px;
	padding: 60px 0px;

	h1 {
		color: $dark_gray;
		font-weight: 500;
		font-size: 48px;
		line-height: 58px;
		margin-bottom: 16px;
		margin-top: 0px;
	}

	.update {
		font-weight: 400;
		font-size: toRem(14);
		line-height: 24px;
		color: $accent_dark;
	}

	h2 {
		margin-top: 40px;
		margin-bottom: 24px;
		font-weight: 400;
		font-size: toRem(24);
		line-height: 32px;
		color: $dark_gray;
	}

	p {
		font-weight: 400;
		font-size: toRem(14);
		line-height: 24px;
		color: $accent_dark;

		b {
			font-weight: 700;
		}
	}

	.list-dash {
		list-style: none;
		margin-left: 0;
		padding-left: 1em;
	}

	.list-dash > li:before {
		display: inline-block;
		content: '-';
		width: 1em;
		margin-left: -1em;
	}

	.email {
		color: $main_color;
	}
}

.page.about {
	.center {
		text-align: center;
	}

	.box {
		padding: 20px;
		border-radius: 8px;
		margin-bottom: 16px;

		span {
			display: inline-block;
			padding: 2px;
			min-width: 30px;
			text-align: center;
			background-color: white;
			border-radius: 8px;
			color: black;
			margin-right: 10px;
		}

		&.blue {
			background-color: $light_blue;
			color: $dark_blue;
		}

		&.yellow {
			background-color: $second_color;
		}

		&.info {
			box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.1);
			background-color: white;
			overflow: hidden;

			.title {
				margin: -20px;
				margin-bottom: 10px;
				padding: 20px;
				border-radius: 8px;
				// background-color: $main_color;
				// color: white;
				background-color: $light_blue;
				color: $dark_blue;
			}

			.desc {
				color: $accent_dark;
			}
		}
	}

	.sign {
		display: block;
		width: 250px;
		margin: auto;
		padding: 20px;
		border-radius: 8px;
		background-color: $main_color;
		color: white;
		text-align: center;
		cursor: pointer;
	}
}

.dispute.myorders.page {
	.empty {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.job {
		.info {
			.cols {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				width: 300px;
			}

			.block {
				display: flex;
				flex-direction: column;
				flex: 1;
			}
		}

		.btn {
			&.text {
				width: 160px;
			}
		}
	}
}

/////////////////////////////

.sign-component {
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	.side {
		height: 100%;
		flex: 1 1 50%;
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		&.left {
			height: calc(100vh - 66px);
			background-color: #fafafa;
			background-image: url('../public/img/sign_bg.jpg');
			background-size: cover;
			background-position: center;
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			font-size: 18px;
			position: relative;
			.info {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				flex: 1;
				span {
					font-weight: bold;
					font-size: 24px;
				}
			}

			.carousel-container {
				position: absolute;
				width: 100%;
				bottom: 40px;
			}

			.carousel-card {
				position: relative;
				transition: transform 1s;
				background-color: #fff;
				border-radius: 14px;
				border: 1px solid $main_color;
				box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
				overflow: hidden;
				padding: 16px;
				display: flex;
				width: 33vw;
				&.step0,
				&.step1 {
					transform: translateX(-100%);
					transition: none;
				}
				&.step2 {
					transform: translateX(10vw);
				}
				&.step3 {
					transform: translateX(70vw);
				}
				&.step4 {
					transform: translateX(10vw);

					&::after {
						content: 'Paused';
						position: absolute;
						text-align: center;
						color: white;
						width: 100%;
						bottom: 105%;
						left: 0;
						right: 0;
					}
				}
				.card-image {
					min-width: 100px;
					height: 100px;
					overflow: hidden;
					border-radius: 14px;
					margin-right: 20px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.card-content {
					width: 100%;
					.card-header {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 8px;
						.info {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							h3 {
								color: #000;
								font-size: 18px;
								margin: 0;
							}
							.location {
								color: $accent_dark;
								font-size: 14px;
								margin: 0;
								line-height: 1;
							}
						}

						.badge {
							font-size: 20px;
						}

						p {
							color: #666;
							font-size: 14px;
						}
					}

					.card-rating {
						margin-top: 0.5rem;
						font-size: 16px;
						color: #f5b301;
						display: flex;
						align-items: center;
						gap: 0.5rem;

						.rating-count {
							color: #666;
							font-size: 0.8rem;
						}
					}

					blockquote {
						margin: 8px 0;
						font-style: italic;
						color: #444;
					}

					.reviewer {
						font-size: 0.9rem;
						font-weight: bold;
						color: #333;
						margin: 0;
					}
					.tags {
						display: flex;
						align-items: flex-end;
						justify-content: flex-start;
						font-size: 14px;
						margin: 0;
						color: #183843;
						.tag {
							padding: 4px 8px;
							border-radius: 4px;
							margin-right: 8px;
							background-color: #d2e4ee;
						}
					}
				}
			}
		}

		&.right {
			height: calc(100vh - 66px);
			background-color: $accent_medium;
			padding: 10px;
			z-index: 100;
		}
	}

	.form {
		width: 360px;

		h4 {
			font-weight: 400;
			font-size: toRem(24);
			line-height: 32px;
			color: black;
			margin: 0;
			margin-bottom: 14px;
			text-align: center;
		}

		.signin {
			font-weight: 400;
			font-size: toRem(12);
			line-height: 16px;
			text-align: center;
			color: black;
			margin-bottom: 26px;

			a {
				color: #626a75;
				margin-left: 8px;
			}
		}

		.or {
			width: 100%;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 100%;
			color: rgba(0, 0, 0, 0.3);
			text-align: center;
			position: relative;
			margin: 16px 0px;

			&::before {
				content: '';
				width: 100%;
				height: 1px;
				background-color: rgba(0, 0, 0, 0.05);
				position: absolute;
				left: 0px;
				top: 50%;
			}

			span {
				background-color: $accent_medium;
				padding: 0px 14px;
				position: relative;
			}
		}

		.type {
			border-radius: 4px;
			font-weight: 400;
			font-size: toRem(12);
			line-height: 16px;
			margin-bottom: 15px;

			span {
				font-size: toRem(14);
				font-weight: 600;
			}
		}

		.base-dropdown {
			text-transform: capitalize;
			background-color: $accent_light;
			padding: 8px 16px;
			border-radius: 6px;
			margin-bottom: 15px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-size: toRem(12);
			line-height: 16px;

			.menu {
				.item {
					font-weight: 400;
					font-size: toRem(12);
					line-height: 16px;
				}
			}
		}

		.line {
			margin-top: 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.field {
				width: 100%;

				&.half {
					flex: 0 0 47%;
					width: 47%;
				}

				.label {
					width: 100%;
					font-weight: 400;
					font-size: toRem(12);
					line-height: 16px;
					color: #3a4554;
					margin-bottom: 4px;
				}

				input {
					width: 100%;
					background-color: $accent_light;
					border-radius: 4px;
					padding: 8px 16px;
					font-weight: 400;
					font-size: toRem(12);
					line-height: 16px;
					border: none;
					transition: box-shadow 0.25s;

					&:focus {
						box-shadow: 0px 4px 20px rgb(150, 163, 181, 0.2);
					}

					&:disabled {
						background-color: #dee3e7;
						border: 2px solid white;
					}
				}
			}
		}

		.error-message {
			height: 25px;
			color: $red;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: toRem(12);
			margin: 16px 0px;

			b {
				margin-right: 4px;
				text-transform: capitalize;
			}
		}

		.terms {
			width: 100%;
			margin-top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 100%;
			color: $accent_dark;
			margin-bottom: 24px;
			transition: 0.25s;
			cursor: pointer;

			// &:hover span {
			// 	border: 1px solid rgba(0, 0, 0, 0.15);
			// 	background-color: rgba(0, 0, 0, 0.05);
			// }

			&.error {
				color: $red;

				span {
					background-color: rgba($red, 0.3);
				}
			}

			.check {
				margin-right: 12px;
				width: 24px;
				height: 24px;
				border-radius: 4px;
				background-color: transparent;
				border: 1px solid rgba(0, 0, 0, 0.1);
				position: relative;
				transition: 0.25s;
				background-position: center;
				background-size: 16px;
				background-repeat: no-repeat;
			}

			// &::after {
			// 	content: "";
			// 	position: absolute;
			// 	left: 8px;
			// 	top: 4px;
			// 	width: 4px;
			// 	height: 9px;
			// 	border: solid white;
			// 	border-width: 0 3px 3px 0;
			// 	-webkit-transform: rotate(45deg);
			// 	-ms-transform: rotate(45deg);
			// 	transform: rotate(45deg);
			// 	opacity: 0;
			// }

			a {
				font-weight: 500;
				margin: 0px 4px;
			}

			input {
				display: none;
				opacity: 0;
				width: 0;
				height: 0;
			}

			input:checked ~ .check {
				background-color: $main_color;
				background-image: url('./pages/Signup/img/done-white.svg');
			}
		}

		.submit {
			width: 100%;
			padding: 16px;
			background-color: $main_color;
			border-radius: 4px;
			color: white;
			font-weight: 400;
			font-size: toRem(16);
			line-height: 100%;
			border: none;
			text-align: center;
			transition: 0.25s;
			cursor: pointer;

			&:hover {
				background-color: lighten($color: $main_color, $amount: 5);
			}
		}

		.confirm-code {
			p {
				text-align: center;
				font-size: toRem(14);
			}

			.inputs {
				display: flex;
				justify-content: space-between;

				input {
					padding: 10px 0px;
					text-align: center;
					width: 15%;
					margin: 0px;
				}
			}
		}
	}

	.stepper {
		width: 100%;
		padding-bottom: 40px;
		min-height: calc(100vh - 230px);

		.header {
			width: 100%;
			background-color: $accent_medium;
			text-align: center;
			padding-top: 74px;
			padding-bottom: 16px;
			margin-bottom: 40px;

			.title {
				font-weight: 400;
				font-size: toRem(24);
				line-height: 32px;
				color: black;
				margin-bottom: 12px;
			}

			.desc {
				font-weight: 400;
				font-size: toRem(12);
				line-height: 16px;
				color: rgba(0, 0, 0, 0.66);
			}
		}

		.waitlist-link {
			color: $main_color;
		}

		& > .wrap {
			width: fit-content;
			margin: auto;
			position: relative;

			.email {
				min-width: 250px;
			}

			.error {
				color: $red;
				margin-top: 10px;
				text-align: center;
			}

			.success {
				color: $green;
				margin-top: 10px;
				text-align: center;
			}

			.title-complete {
				font-weight: 400;
				line-height: 16px;
				text-align: center;
				margin: 10px;
			}
		}

		.steps {
			width: 100%;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 15px;
			margin-bottom: 32px;

			.step {
				font-weight: 400;
				font-size: toRem(12);
				line-height: 16px;
				color: #adadad;
				display: flex;
				justify-content: space-between;
				align-items: center;
				transition: 0.25s;

				img {
					margin-right: 5px;
					opacity: 0;
					transition: 0.25s;
				}

				&.completed {
					img {
						opacity: 1;
					}
				}

				&.current {
					color: rgba(0, 0, 0, 0.66);
				}
			}
		}

		.categories-list {
			position: absolute;
			width: 262px;
			height: 100%;
			top: 0px;
			left: calc(100% + 32px);

			.sticky {
				position: sticky;
				top: 20px;
				padding-bottom: 20px;
			}

			.block {
				background-color: $accent_light;
				border-radius: 4px;
				padding: 12px 16px;
				// height: calc(100vh - 100px);
				overflow: auto;
			}

			.confirm {
				margin-top: 16px;
			}

			.title {
				font-weight: 400;
				font-size: toRem(14);
				line-height: 24px;
				color: #3a4554;
			}

			.cat {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 8px 16px;
				background: rgba(0, 0, 0, 0.03);
				// border: 1px solid rgba(0, 0, 0, 0.025);
				border-radius: 4px;
				font-weight: 400;
				font-size: toRem(12);
				line-height: 16px;
				margin-top: 5px;
				transition: 0.25s;
				cursor: pointer;

				&:hover {
					background: rgba(0, 0, 0, 0.06);
				}

				img {
					margin-right: 10px;
				}
			}
		}

		.content {
			width: 360px;
			margin: auto;

			&.categories {
				width: 530px;
			}

			.personal-info-header {
				text-align: center;
				font-weight: 400;
			}

			.dob-label {
				font-size: 0.875rem;
				line-height: 24px;
			}

			.dob-input {
				font-size: 0.875rem;
				line-height: 24px;
				padding-left: 4px;
				padding-right: 4px;
			}

			.disabled {
				color: gray;
			}

			.desc {
				font-weight: 400;
				font-size: toRem(12);
				line-height: 16px;
				color: #3a4554;

				&.center {
					text-align: center;
				}
			}

			.rate {
				margin: 20px 0px;
				display: flex;
				justify-content: center;
				align-items: center;

				input {
					width: 80px;
					margin-right: 16px;
				}

				span {
					font-weight: 400;
					font-size: 0.875rem;
					line-height: 24px;
					color: #76879d;
				}
			}

			.contact {
				margin-bottom: 42px;
				color: black;
				font-weight: 400;
				font-size: toRem(16);
				line-height: 16px;
				text-align: center;
			}

			.phone-confirmation {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				p {
					font-size: 14px;
					color: $accent_dark;
				}
				.phone-input {
					input {
						width: 250px;
						border-radius: 4px;
						padding: 8px 16px;
						font-weight: 400;
						line-height: 16px;
						// border: none;
						border: 1px solid rgba(0, 0, 0, 0.1);
						margin-bottom: 16px;
					}
				}

				.timer-container {
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					.timer-text {
						font-size: 14px;
						color: $accent_dark;
						margin: 0;
						margin-right: 5px;
					}

					.timer {
						font-size: 16px;
						font-weight: bold;
						color: $accent_dark;
					}
				}
				.show {
					opacity: 1;
				}

				.btn {
					width: 100%;
					display: block;
					margin-top: 10px;
					background-color: $accent_dark;
					color: white;
					padding: 16px;
					border-radius: 4px;
					font-weight: 400;
					font-size: toRem(16);
					line-height: 100%;
					border: none;
					cursor: pointer;
					transition: 0.25s;
				}
				.resend {
					width: 250px;
				}
				.confirm {
					margin-top: 20px;
				}
				.confirm-code {
					margin-top: 20px;
					p {
						text-align: center;
						font-size: toRem(14);
					}
				}
				.error {
					color: red;
					font-size: 14px;
					height: 18px;
					margin-bottom: 5px;
					display: flex;
					align-items: center;
					opacity: 1;
					transition: opacity 0.5s ease-in-out;
				}
			}
		}

		.confirm {
			width: 100%;
			display: block;
			margin-top: 32px;
			background-color: $accent_dark;
			color: white;
			padding: 16px;
			border-radius: 4px;
			font-weight: 400;
			font-size: toRem(16);
			line-height: 100%;
			border: none;
			cursor: pointer;
			transition: 0.25s;

			&:hover {
				background-color: darken($color: $accent_dark, $amount: 5);
			}

			&:disabled {
				background-color: $accent_medium;
				cursor: not-allowed;
			}
		}

		.categories {
			margin-top: 45px;
			padding-bottom: 40px;

			.item {
				padding: 10px 15px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 8px;
				margin-top: 5px;
				background-color: $accent_light;
				display: flex;
				justify-content: space-between;

				.price {
					input {
						padding: 6px 8px;
					}
				}
			}
		}

		.base-label {
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
			margin-bottom: 8px;
			margin-top: 16px;
		}

		.base-input {
			padding: 12px 16px;
		}

		.checkbox-container {
			display: flex;
			align-items: center;
			margin-top: 16px;
			margin-bottom: 16px;

			.checkbox {
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				transition: 0.25s;
				margin-right: 10px;

				&:hover {
					background-color: rgba(0, 0, 0, 0.05);
				}

				&.checked {
					background-color: $main_color;
					border-color: $main_color;
				}
			}

			span {
				font-weight: 400;
				font-size: toRem(12);
				line-height: 16px;
				color: #3a4554;
			}
		}
	}
}

.tags-select {
	.btn {
		display: inline-flex;
		gap: 10px;
		padding: 10px 16px;
		background-color: white;
		color: black;
		border-radius: 4px;
		font-weight: 400;
		font-size: toRem(12);
		line-height: 100%;
		margin-right: 8px;
		margin-bottom: 12px;
		transition: 0.25s;
		cursor: pointer;
	}

	.add {
		background-color: $main_color;
		color: white;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;

		.arrow {
			width: 12px;
			margin-left: 10px;
			transition: 0.25s;
		}

		&:hover {
			background-color: lighten($color: $main_color, $amount: 5);
		}
	}

	.tag {
		background-color: $second_color;
		color: white;

		&:hover {
			background-color: darken($color: $second_color, $amount: 5);
		}
	}
}

.review {
	text-align: left;

	.info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 16px;
		margin-bottom: 16px;
		border-bottom: 1px solid #f2f2f2;

		.avatar {
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $accent_gray;
			border-radius: 12px;
			margin-right: 8px;

			img {
				width: 15px;
				display: block;
			}
		}

		.about {
			flex: auto;

			.name {
				font-weight: 600;
				font-size: toRem(14);
				line-height: 20px;
			}

			.job {
				font-weight: 400;
				font-size: toRem(12);
				line-height: 16px;
				color: $accent_dark;
			}

			.review-title {
				max-width: 340px;
			}
		}
	}

	.rating {
		display: flex;
		justify-content: space-between;
		align-items: center;

		// padding: 16px 0px;
		margin: 16px 0px;
		// border-bottom: 1px solid #F2F2F2;

		.title,
		.value {
			font-size: toRem(12);
			line-height: 16px;
			color: $accent_dark;
		}

		.value {
			flex: auto;
			text-align: right;
			margin-right: 10px;
		}

		.stars {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			top: -1px; // Fixed
		}

		.star {
			display: block;
			width: 20px;
			cursor: pointer;

			&.unstared {
				opacity: 0.3;
			}
		}
	}
	.base-edit {
		.base-input {
			background-color: $accent_light;
		}
	}

	.bottom {
		display: flex;
		justify-content: flex-end;
		margin-top: 16px;
	}
}

.signin-modal {
	padding: 32px 75px;
	height: auto;
	background-color: white;
	border-radius: 8px;
	position: relative;
	z-index: 1000;

	.form {
		width: 400px;

		.types {
			margin-bottom: 20px;
			display: flex;
			flex-direction: row;
			.type {
				flex: 1;
				padding: 10px 20px;
				border: 2px solid #007bff;
				background-color: #ffffff;
				color: #007bff;
				font-weight: bold;
				cursor: pointer;
				transition: all 0.3s ease;
				border-radius: 0 4px 4px 0;
				&:first-child {
					border-radius: 4px 0 0 4px;
				}
			}
			.selected {
				background-color: #007bff;
				color: #ffffff;
			}
		}

		.or span {
			background-color: white;
		}

		input {
			padding: 10px 24px;
			background: #ffffff;
			border: 1px solid #ebebeb;
			border-radius: 4px;
			width: 100%;
			font-weight: 400;
			font-size: toRem(16);
			line-height: 100%;
			color: rgba(0, 0, 0, 0.5);
			margin: 16px 0px;
			display: block;
		}

		.phone-title {
			text-align: center;
		}

		.reset {
			text-align: center;
			color: $accent_gray;
			font-size: toRem(14);
			margin-top: 10px;
			cursor: pointer;
			transition: 0.25s;

			&:hover {
				color: $accent_dark;
			}
		}
		.timer-container {
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0;
			.timer-text {
				font-size: 14px;
				color: $accent_dark;
				margin: 0;
				margin-right: 5px;
			}

			.timer {
				font-size: 16px;
				font-weight: bold;
				color: $accent_dark;
			}
		}
		.show {
			opacity: 1;
		}

		.submit {
			margin-top: 30px;
		}
		.phone {
			margin-top: 0;
		}
		.send-code {
			margin-top: 0px;
			&:disabled {
				background-color: $dark_gray;
			}
		}
		.confirm-code {
			margin-top: 30px;
		}
	}

	.close {
		position: absolute;
		top: 24px;
		right: 24px;
		cursor: pointer;
		transition: 0.25s;

		&:hover {
			transform: scale(1.25);
		}
	}
}

.overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgba(118, 135, 157, 0.51);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;

	visibility: hidden;
	opacity: 0;
	transition: 0.5s;

	&.instant {
		transition: none;
	}

	&.show {
		visibility: visible;
		opacity: 1;
	}
}

.loader {
	background-color: rgba(241, 243, 246, 0.75);
	// backdrop-filter: blur(3px);
	position: absolute;
	left: -10px;
	top: 0px;
	right: -10px;
	bottom: 0px;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;

	@keyframes loading {
		to {
			transform: rotate(1turn);
		}
	}

	.spiner {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 10vw;

		&.sticky {
			position: sticky;
		}

		&::after {
			content: '';
			width: 60px;
			height: 60px;
			border: 15px solid $accent_gray;
			border-top-color: transparent;
			border-radius: 50%;
			animation: loading 1s linear infinite;
		}
	}
}

.modal-popup {
	text-align: left;

	.center-text {
		text-align: center;
	}

	.info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 16px;
		margin-bottom: 16px;
		border-bottom: 1px solid #f2f2f2;

		.avatar {
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $accent_gray;
			border-radius: 12px;
			margin-right: 8px;

			img {
				width: 15px;
				display: block;
			}
		}

		.about {
			flex: auto;

			.name {
				font-weight: 600;
				font-size: toRem(14);
				line-height: 20px;
			}

			.title {
				font-weight: 400;
				font-size: toRem(12);
				line-height: 16px;
				color: $accent_dark;
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: space-around;

		.btn {
			width: 45%;
			padding: 12px 16px;
			background-color: $main_color;
			color: white;
			border-radius: 4px;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 100%;
			border: none;
			cursor: pointer;
			transition: 0.25s;
			text-align: center;

			&:hover {
				background-color: lighten($color: $main_color, $amount: 5);
			}
		}

		.red {
			background-color: $red;
			color: white;

			&:hover {
				background-color: lighten($color: $red, $amount: 5);
			}
		}
	}

	.bottom {
		display: flex;
		justify-content: flex-end;
		margin-top: 16px;
	}

	.base-completed {
		top: 8px;
		left: 8px;
		right: 8px;
		bottom: 8px;
	}

	.base-input {
		border: 1px solid $accent_medium;
		border-radius: 16px;
	}
}

////////////////////////////

.app-notifications {
	position: fixed;
	bottom: 30px;
	left: 30px;
	width: 400px;
	display: flex;
	z-index: 1000000;
	flex-direction: column-reverse;

	@keyframes notificationShow {
		from {
			opacity: 0;
			transform: translateX(-150%);
		}

		to {
			opacity: 1;
			transform: translateX(0px);
		}
	}

	@keyframes notificationHide {
		from {
			opacity: 1;
			transform: translateX(0px);
		}

		to {
			opacity: 0;
			transform: translateX(-150%);
		}
	}

	.item {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		margin-top: 15px;
		padding: 15px;
		border-radius: 8px;
		background-color: white;
		box-shadow: 0px 4px 25px rgba(150, 163, 181, 0.4);
		transition: 0.25s;
		cursor: pointer;

		animation: notificationShow ease-in-out 0.5s forwards;

		&.hide {
			animation: notificationHide ease-in-out 0.5s forwards;
		}

		// opacity: 0;
		// transform: translateX(-120%);

		// &.hide {
		// 	opacity: 1;
		// 	transform: translateX(0px);
		// }

		&:hover {
			box-shadow: 0px 4px 20px 15px rgba(150, 163, 181, 0.3);
		}

		.icon {
			min-width: 40px;
			width: 40px;
			height: 40px;
			background-color: $main_color;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				display: block;
				width: 25px;
			}
		}

		.message {
			flex: auto;
			margin-left: 15px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			width: 100%;

			.title {
				display: block;
				width: 100%;
				font-size: toRem(15);
				font-weight: 600;
			}

			.content {
				font-size: toRem(13);
				line-height: 18px;
				color: #333;
			}

			.date {
				width: 100%;
				font-size: toRem(11);
				font-weight: 500;
				opacity: 0.35;
				margin-top: 4px;
				transition: 0.25s;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		&.error .icon {
			background-color: $red;
		}

		&.success .icon {
			background-color: $green;
		}
	}
}

.google-sign {
	width: 100%;
	background-color: #d0463b;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	color: white;
	padding: 16px;
	font-weight: 400;
	font-size: toRem(16);
	line-height: 16px;
	border: none;
	text-align: center;
	position: relative;
	transition: 0.25s;
	cursor: pointer;

	&:hover {
		background-color: lighten($color: #d0463b, $amount: 5);
	}

	.icon {
		width: 32px;
		height: 32px;
		border-radius: 4px;
		background-color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 8px;
		top: 8px;

		img {
			width: 18px;
			height: 18px;
		}
	}
}

.dates-slider {
	width: 100%;
	position: relative;

	.arrow {
		position: absolute;
		top: calc(50% - 5px);
		transition: scale 0.25s;
		cursor: pointer;

		&:hover {
			scale: 2;
		}

		&.disabled {
			opacity: 0.1;
		}

		&.left {
			transform: rotate(180deg);
			left: -10px;
		}

		&.right {
			right: -10px;
		}
	}

	.overflow {
		width: 100%;
		height: 120px;
		overflow: hidden;
	}

	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		transition: 0.5s;
	}

	.item {
		width: 120px;
		min-width: 120px;
		max-width: 120px;
		margin: 0px 5px;
		background: white;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		transition: 0.25s;
		// box-shadow: 0px 2px 5px 10px rgba(150, 163, 181, 0.2);
		border: 1px solid rgba(0, 0, 0, 0.1);
		cursor: pointer;
		position: relative;

		&:hover {
			background: $second_color;
		}

		&.selected {
			background-color: $main_color;
			color: white;
		}

		div {
			width: 100%;
			text-align: center;
		}

		span {
			img {
				width: 18px;
				height: 18px;
				transform: scaleX(-1);
			}
			position: absolute;
			right: 5px;
			top: 3px;
			height: 20px;
			width: 20px;
			border-radius: 100%;
		}
		.month {
			font-weight: 400;
			font-size: toRem(12);
			line-height: 24px;
			// color: #3A4554;
			opacity: 0.7;
		}

		.date {
			font-weight: 400;
			font-size: toRem(32);
			line-height: 40px;
			margin: 4px;
		}

		.day {
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
		}

		&.disabled {
			opacity: 0.3;
			background: white;
			cursor: not-allowed;
		}
	}
	.overnight {
		// background-color: #2e4482e4;
	}
}

.paypal-connect-button {
	padding: 6px 20px;
	border-radius: 4px;
	color: white;
	background-color: $main_color;
	border: none;
	cursor: pointer;
	transition: 0.25s;

	&:hover {
		background-color: lighten($color: $main_color, $amount: 5);
	}

	&:disabled {
		background-color: $accent_dark;
	}
}

.button-group {
	display: flex;
	gap: 8px;
	flex-direction: row;
	align-items: center;
}

.availability-input-container {
	width: 100%;

	.overflow {
		width: 100%;
		overflow: scroll;
	}

	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		transition: 0.5s;
		gap: 10px;
	}

	.label-text {
		font-size: 0.875rem;
		line-height: 24px;
		font-weight: 400;
	}

	.input-text {
		font-size: 0.875rem;
		line-height: 24px;
		font-weight: 400;
		padding-left: 5px;
	}

	.item {
		// width: 120px;
		// max-width: 120px;
		min-width: 120px;
		background: white;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		transition: 0.25s;
		border: 1px solid rgba(0, 0, 0, 0.1);
		cursor: pointer;

		&:hover {
			background: $second_color;
		}

		&.selected {
			background-color: $main_color;
			color: white;
		}

		div {
			width: 100%;
			text-align: center;
		}

		.day {
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
		}
	}

	.time-slot-container {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-start;
		padding-right: 4px;
	}

	.delete {
		cursor: pointer;
	}

	.time-slot {
		gap: 4px;
		width: 100%;
	}

	.time-slot-square {
		padding: 4px;
		background: rgba(0, 0, 0, 0.05);
		border: 1px solid rgba(0, 0, 0, 0.25);
		border-radius: 5px;
	}
}

.base-dropdown {
	display: inline-block;
	position: relative;
	cursor: pointer;

	.arrow {
		transition: 0.25s;
	}

	&.opened .arrow {
		transform: rotate(180deg);
	}

	.menu {
		position: absolute;
		top: calc(100% + 5px);
		left: 0px;
		right: 0px;
		visibility: hidden;
		opacity: 0;
		z-index: 1000;
		transition: 0.2s;
		max-height: 60vh;
		overflow: auto;

		background: #ffffff;
		box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.14);
		border-radius: 4px;

		&.show {
			visibility: visible;
			opacity: 1;
		}

		.empty {
			padding: 12px 16px;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
			color: $accent_gray;
			text-align: center;
		}

		.item {
			padding: 12px 24px;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
			color: $accent_dark;
			border-top: 1px solid transparent;
			border-bottom: 1px solid rgba(205, 211, 220, 0.15);
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: 0.25s;

			&.unselect {
				// justify-content: center;
				text-align: center;
				color: $accent_gray;
				// font-weight: 300;
			}

			&:hover {
				border-top: 1px solid rgba(205, 211, 220, 0.15);
				background-color: rgba(205, 211, 220, 0.15);
			}

			img {
				width: 16px;
				height: 16px;
			}

			span {
				margin-left: 12px;
			}
		}
	}
}

.base-input {
	width: 100%;
	background: white;
	border-radius: 4px;
	padding: 8px 16px;
	font-weight: 400;
	font-size: toRem(12);
	line-height: 16px;
	border: none;

	&.white {
		background-color: white;
	}
}

textarea.base-input {
	display: block;
	padding: 16px;
}

.base-label {
	font-weight: 400;
	font-size: toRem(12);
	line-height: 16px;
	margin-bottom: 4px;
}

.base-radio {
	margin-right: 15px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;

	input {
		display: none;
	}

	input:checked ~ .check {
		background-color: $main_color;
	}

	.check {
		background-color: white;
		outline: 1px solid $accent_dark;
		border: 3px solid white;
		border-radius: 100%;
		width: 16px;
		height: 16px;
		margin-right: 8px;
		transition: 0.25s;
	}

	span {
		font-weight: 400;
		font-size: toRem(14);
		line-height: 20px;
		color: $accent_dark;
	}
}

.confirm-code {
	width: 232px;
	margin: auto;

	p {
		margin: 0px;
		margin-bottom: 4px;
		color: #3a4554;
		font-weight: 400;
		font-size: toRem(12);
		line-height: 16px;
	}

	.inputs {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		input {
			background-color: #fff;
			border: 1px solid rgba(0, 0, 0, 0.1);
			width: 32px;
			height: 32px;
			border-radius: 4px;
			text-align: center;
		}
	}
}

.base-checkbox {
	color: $accent_dark;
	font-weight: 400;
	font-size: toRem(12);
	line-height: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;

	.check {
		flex: 0 0 16px;
		width: 16px;
		height: 16px;
		margin-right: 8px;
		border: 1px solid rgb(0, 0, 0, 0.2);
		background-color: white;
		border-radius: 4px;
		transition: background-color 0.25s;
	}

	input {
		display: none;
	}

	input:checked ~ .check {
		background-image: url('./pages/Signup/img/done-white.svg');
		background-position: center;
		background-size: 10px;
		background-repeat: no-repeat;
		background-color: $main_color;
	}
}

.base-select {
	position: relative;

	&::after {
		content: '';
		background-image: url('./components/Base/img/dropdown-arrow.svg');
		background-repeat: no-repeat;
		background-position: center;
		width: 20px;
		height: 20px;
		position: absolute;
		top: calc(50% - 10px);
		right: 10px;
		transition: 0.25s;
	}

	&.opened::after {
		transform: rotate(180deg);
	}
}

.base-completed {
	position: absolute;
	top: 0px;
	left: -30px;
	right: -30px;
	bottom: 0px;
	background-color: rgba(241, 243, 246, 0.5);
	backdrop-filter: blur(3px);
	transition: 0.5s;
	visibility: hidden;
	opacity: 0;
	z-index: 1999999;

	&.show {
		visibility: visible;
		opacity: 1;
	}

	.icon {
		position: sticky;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 10vw;
		height: 10vw;
		padding: 2vw;
		border-radius: 100%;
		background-color: $main_color;

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

.base-tabs {
	.dropdown {
		display: inline-block;
	}

	.dropdown-menu {
		display: block;
		position: absolute;
		top: calc(100% + 6px);
		right: -10px;
		background-color: #f9f9f9;
		min-width: 220px;
		padding: 4px;
		border-radius: 8px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1000;
		overflow: auto;
	}

	& > .header {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: white;
		box-shadow: 0px 4px 24px rgba(150, 163, 181, 0.15);
		border-radius: 8px;
		margin-bottom: 24px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		width: 100%;
		z-index: 1000;

		&.sticky {
			position: sticky;
			// background-color: $accent_light;
			top: 10px;
		}

		.tab {
			// border-bottom: 3px solid rgba(0, 0, 0, 0);
			padding: 12px 30px;
			// padding-bottom: 13px;
			margin: 0px 5px;
			font-weight: 400;
			font-size: toRem(14);
			line-height: 24px;
			text-transform: capitalize;
			transition: 0.25s;
			cursor: pointer;

			span {
				display: inline-block;
				text-align: center;
				color: white;
				font-weight: 400;
				background: $main_color;
				border-radius: 4px;
				padding: 0px 5px;
				min-width: 24px;
				margin-left: 5px;
			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.04);
			}

			&.selected {
				background-color: $main_color;
				color: white;

				span {
					color: black;
					background-color: white;
				}
			}
		}
	}

	.empty {
		text-align: center;
		padding: 32px;
		color: $accent_gray;
		font-size: toRem(16);

		span {
			text-transform: capitalize;
		}
	}
}

.base-modal {
	padding: 30px;
	padding-top: 40px;
	background-color: white;
	border-radius: 16px;
	position: relative;

	.paid {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 30px;
			display: block;
			margin-right: 10px;
		}

		span {
			color: $green;
			font-weight: 500;
		}
	}

	p.small {
		font-size: 14px;
		font-weight: 300;
	}

	&.center {
		text-align: center;
	}

	& > .close {
		position: absolute;
		top: 25px;
		right: 25px;
		width: 20px;
		height: 20px;
		opacity: 0.5;
		transition: 0.25s;
		cursor: pointer;

		&:hover {
			opacity: 1;
			transform: scale(1.5);
		}
	}

	h2 {
		margin: 0px;
		margin-bottom: 15px;
		font-weight: 500;
		font-size: toRem(20);
	}
}

.base-button {
	font-weight: 400;
	font-size: toRem(14);
	line-height: 24px;
	padding: 8px 16px;
	border-radius: 4px;
	background-color: $main_color;
	color: white;
	white-space: nowrap;
	transition: 0.25s;
	border: none;
	cursor: pointer;

	&:hover {
		background-color: lighten($color: $main_color, $amount: 5);
	}

	&:disabled {
		background-color: $accent_medium;
	}
}

.base-edit {
	position: relative;

	textarea {
		resize: none;
	}

	.length {
		min-width: 65px;
		text-align: center;
		background: rgba(0, 0, 0, 0.05);
		border-radius: 6px;
		padding: 5px 10px;
		font-weight: 400;
		font-size: toRem(12);
		line-height: 16px;
		color: $accent_dark;
		position: absolute;
		right: 16px;
		bottom: 16px;
	}
}

.base-scroll {
	&::-webkit-scrollbar {
		background-color: transparent;
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(0, 0, 0, 0.2);
		border-radius: 8px;
	}
}

@media screen and (max-width: 1100px) {
	html,
	body,
	#root,
	.app {
		overflow: auto;
		overflow-x: hidden;
		min-height: 100vh;
		max-height: 100vh;
		font-size: 14px;
	}

	.page {
		width: 100vw !important;
		padding-left: 3vw;
		padding-right: 3vw;

		& > h1 {
			margin: 3vw 0px;
		}
	}

	.tags-select .add .arrow {
		width: 10px;
	}

	.home.page {
		.search {
			height: auto;
			margin-bottom: 30px;

			form {
				flex-direction: column;
				height: auto;
				gap: 12px;
				padding: 15px;

				.block {
					width: 100% !important;
					padding: 10px;
					border-left: none;
					border-bottom: 1px solid #eee;

					&:last-child {
						border-bottom: none;
					}
				}

				.query.block {
					padding: 10px;

					input {
						width: 100%;
						margin-left: 10px;
					}
				}

				.category.block {
					display: block;

					.base-dropdown {
						padding: 8px 15px;
					}
				}

				.date.block {
					display: flex;
					flex-direction: column;
					gap: 10px;
					padding: 15px;

					.input-wrapper {
						width: 100%;

						input {
							width: 100%;
							padding: 8px;
							border: 1px solid #eee;
							border-radius: 4px;
						}

						.placeholder {
							left: 10px;
						}
					}
				}

				.city.block {
					display: block;

					input {
						padding: 8px;
					}
				}

				.find {
					width: 100%;
					min-width: 100%;
					border-radius: 4px;
					padding: 12px;
				}
			}

			.sort {
				display: block;
				padding: 15px 5px;
				margin-top: 10px;
				border-top: 1px solid #eee;
			}
		}
	}

	.job.page {
		display: block;

		& > .left,
		& > .right {
			width: 100%;
		}

		.order {
			width: 100vw;
			padding: 5vw;

			h2 {
				margin-bottom: 8vw;
			}

			.row {
				gap: 4vw;
				align-items: stretch;
				margin-bottom: 6vw;
			}

			.box {
				padding: 4vw;
				margin-bottom: 6vw;

				p {
					line-height: initial;
				}
			}

			.price {
				padding: 0px;

				.right {
					padding: 4vw 6vw;
				}
			}
		}
	}

	.profile.page {
		padding-top: 4vw;

		.sides {
			display: block;

			& > .left {
				.more {
					margin-left: 0px;

					.tags {
						margin-top: 2vw;
						padding-top: 2vw;

						.item {
							padding: 2vw;
							margin-right: 2vw;
							margin-bottom: 2vw;
						}
					}
				}

				.about {
					margin-left: 0px;
					padding: 6vw;
					margin-top: 4vw;
					margin-bottom: 4vw;

					.list .item .full {
						display: none !important;
					}

					.list .item .right img {
						display: none !important;
					}

					.base-tabs .tab {
						flex: 1;
					}
				}
			}

			& > .right {
				position: initial;
				margin-left: 0px;
				width: 100%;
			}
		}

		.column-flex {
			display: flex;
			flex-direction: column;
		}

		.dates-slider {
			width: 100%;
			position: relative;

			.overflow {
				width: 100%;
			}

			.container {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				transition: 0.5s;
			}

			.item {
				width: 120px;
				min-width: 120px;
				max-width: 120px;
				margin: 0px 5px;
				background: white;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				flex-wrap: wrap;
				transition: 0.25s;
				border: 1px solid rgba(0, 0, 0, 0.1);
				cursor: pointer;

				&:hover {
					background: $second_color;
				}

				&.selected {
					background-color: $main_color;
					color: white;
				}

				div {
					width: 100%;
					text-align: center;
				}

				.day {
					font-weight: 400;
					font-size: toRem(14);
					line-height: 24px;
				}
			}
		}
	}

	.button-group {
		display: flex;
		gap: 8px;
		flex-direction: row;
		align-items: center;
	}

	.availability-title {
		flex-direction: column !important;
		gap: 16px;
		width: 100%;
		align-items: flex-start !important;
	}

	.availability-input-container {
		width: 100%;
		overflow: scroll;

		.container {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			transition: 0.5s;
			gap: 10px;
		}

		.label-text {
			font-size: 0.875rem;
			line-height: 24px;
			font-weight: 400;
		}

		.input-text {
			font-size: 0.875rem;
			line-height: 24px;
			font-weight: 400;
			padding-left: 5px;
		}

		.item {
			// width: 120px;
			// max-width: 120px;
			min-width: 120px;
			// height: fit-content;
			background: white;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			flex-wrap: wrap;
			transition: 0.25s;
			border: 1px solid rgba(0, 0, 0, 0.1);
			cursor: pointer;

			&:hover {
				background: $second_color;
			}

			&.selected {
				background-color: $main_color;
				color: white;
			}

			div {
				width: 100%;
				text-align: center;
			}

			.day {
				font-weight: 400;
				font-size: toRem(14);
				line-height: 24px;
			}
		}

		.time-slot-container {
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: flex-start;
			padding-right: 4px;
		}

		.delete {
			cursor: pointer;
		}

		.time-slot {
			gap: 4px;
		}
	}

	.time-slots-container {
		display: flex;
		flex-direction: row !important;
		gap: 16px;
	}

	.job-create.page {
		padding-bottom: 7vw;

		h2 {
			margin-bottom: 4vw;
		}

		.error {
			margin: 0px;
			margin-bottom: 2vw;
			margin-top: 2vw;
		}

		.block {
			padding: 4vw 0px;
		}

		.tags-select .tag {
			margin-bottom: 1vw;
			margin-right: 1vw;
		}

		.budget .price {
			margin: 0px 3vw;
			padding: 2vw 4vw;

			input {
				width: 50px;
			}
		}

		.budget {
			position: relative;

			.on-hover-tip {
				position: static;

				.text-popup {
					left: 0px;
					right: 0px;
					top: 40px;
					line-height: 1.5;
					width: 100%;
				}
			}
		}

		.dates-slider {
			.arrow {
				display: none;
			}

			.container {
				overflow: auto;
				transform: translate(0px) !important;
			}
		}

		.times .base-radio span {
			font-size: toRem(11);
		}
	}

	.bids.page .bid {
		.avatar {
			flex: 0 1 60px;
			min-width: 60px;
			height: 60px;
			border-radius: 8px;
		}

		.content {
			margin-left: 3vw;
			margin-right: 0px;

			.info {
				display: block;
			}

			.more .buttons {
				max-width: 100%;
			}
		}
	}

	.myorders.page {
		.base-tabs > .header > .tab {
			font-size: toRem(12);
		}

		.job {
			padding: 5vw;

			.info {
				display: block;

				.cols {
					width: 100%;
				}
			}

			.buttons {
				width: 100%;
				justify-content: space-between;

				.btn.text {
					flex: 1;
				}

				.btn:first-child {
					margin-left: 0px;
				}
			}
		}
	}

	.mybids.page .bid {
		padding: 5vw;

		.content {
			margin: 0px;

			.info {
				display: block;
			}
		}
	}

	.profile-edit.page {
		.base-tabs .header {
			overflow: auto;

			.tab {
				line-height: initial;
			}
		}

		.edit-tab {
			width: 100%;
		}

		.payments-tab {
			height: initial;
			display: block;

			.left {
				.box,
				.form,
				.card {
					width: 100%;
				}
			}

			.history {
				margin-left: 0px;
				margin-top: 4vw;

				.item .date {
					font-size: toRem(10);
				}
			}
		}
	}

	.profile-user {
		margin-bottom: 4vw;

		.button {
			display: none;
		}
	}

	.profile-summary {
		width: 100%;
		min-width: 100%;
	}

	.sign-component {
		.side {
			width: 100%;

			&.left {
				display: none;
			}
		}
	}

	.signin-modal {
		width: 95vw;
		padding: 6vw 4vw;
	}

	.base-modal {
		margin: 0px 2vw;
	}

	.base-tabs > .header {
		justify-content: space-between;
		// overflow: hidden;

		& > .tab {
			flex: 1;
			padding: 3vw 0vw;
			text-align: center;
			margin: 0px;

			span {
				display: inline;
				margin-left: 2px;
			}
		}
	}

	.app-notifications {
		width: initial;
		left: 20px;
		right: 20px;
		bottom: 20px;
	}

	.app-header {
		width: 100vw;
		// overflow: hidden;

		.links {
			display: none;
		}

		.wrap {
			min-width: 100vw;
			padding: 0px 5vw;
		}

		.menu-links {
			display: none;
		}

		.balance {
			display: none;
		}

		.mobile-chat {
			display: block;
			padding: 8px 12px;
		}

		.search {
			display: none;
		}

		.menu .item.mobile-balance {
			display: flex !important;
		}

		.menu .jobs {
			display: block !important;
		}
	}

	.app-footer {
		.wrap {
			width: 100vw;
			overflow: hidden;
		}

		.links,
		.bottom {
			padding: 5vw 0px;
		}

		.links {
			display: block;

			.pages {
				justify-content: center;

				a {
					margin: 2vw;
				}
			}

			.social {
				width: 100%;
				justify-content: center;

				a {
					margin: 2vw;
				}
			}
		}

		.bottom {
			display: flex;
			flex-direction: column-reverse;

			.policy {
				a {
					margin: 2vw;
					display: inline-block;
				}
			}
		}
	}
}
